import React from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import GolfExperianceDetails from './GolfExperianceDetails';

const SearchDetailsPage = () => {

    return (
        <Layout>
            <GolfExperianceDetails/>
        </Layout>
    )
}

export default SearchDetailsPage;
