import React, { useState, useEffect,useRef} from 'react';
import paymenttrip from '../../assets/images/Rectangle 13132.png';
import Noimage from "../../assets/images/no-img.png";
const Instant=({datas})=>{

    const [courseData, setCourses] = useState([]);
    const [hotelData, setHotels] = useState([]);

    useEffect(()=>{
        setCourses(datas?.instant_package?.selected_course_relation?.filter(item =>
          item.status !== 5
                  ))
        setHotels(datas?.instant_package?.selected_hotel_relation?.filter(item =>
          item.status !== 5
                  ))
    },[])
    return(
        <>
        <h3>Courses</h3>
        {courseData?.map((courses)=>(
        <div className='pay-tripsummary-details'>
              <div className='payment-tripsumimg'>
                <img 
                 onError={(e) => {
                  e.target.onerror = null; // Prevent infinite loop
                  e.target.src = Noimage; // Replace with default image if original image fails to load
                }}
                src={courses?.golf_course_image} alt="image" />
              </div>
              <div className='payment-tripsumcont'>
                <h5>{courses?.golf_course_name}</h5>
                {/* <p className='pay-triphotel'>3 Day/4 Nights Stay at Melbourne</p> */}
                <p>No Of Golfers - {courses?.no_of_golfers}</p>
                <p>Tea Time - {courses?.tee_time} </p>
                {/* <p className='pay-tripsum-total'><span>Price of Golfer</span>USD {courses?.price_of_golfers}/Per Person</p> */}
              </div>
        </div>
         ))}
          <h3>Hotels</h3>
        {hotelData?.map((hotel)=>(
        <div className='pay-tripsummary-details'>
              <div className='payment-tripsumimg'>
                <img 
                 onError={(e) => {
                  e.target.onerror = null; // Prevent infinite loop
                  e.target.src = Noimage; // Replace with default image if original image fails to load
                }}
                src={hotel?.hotel_image} alt="image" />
              </div>
              <div className='payment-tripsumcont'>
                <h5>{hotel?.hotel_name}</h5>
                <p className='pay-triphotel'>{hotel?.adults} Adults ,{hotel?.child} Childrens Stay at {hotel?.hotel_name}</p>
                <p>Check-in-date - {hotel?.check_in_date}    </p>
                <p>Check-out-date - {hotel?.check_out_date} </p>
                <p className='pay-tripsum-total'><span>Price for Room</span>USD {hotel?.total_price}/Per Room</p>
              </div>
        </div>
         ))}
  </>
    )
}
export default Instant