import React, { useState, useEffect,useRef} from 'react';
import Noimage from "../../assets/images/no-img.png";
const Course=({datas})=>{
    const [coursesList, setCourses] = useState([]);

    useEffect(()=>{
   
      const coursedata =datas?.golf_course?.booking_details?.filter(item =>
        item.status !=5
                )
    console.log("coursedata",coursedata)
     setCourses(datas?.golf_course?.booking_details?.filter(item =>
      item.status !== 5
              ))
        // setCourses(datas?.golf_course)
    },[])
    return(
        <>
        {coursesList?.map((courses)=>(
        <div className='pay-tripsummary-details'>
              
              
                <>
                <div className='payment-tripsumimg'>
                <img 
                onError={(e) => {
                  e.target.onerror = null; // Prevent infinite loop
                  e.target.src = Noimage; // Replace with default image if original image fails to load
                }}
                src={courses?.image} 
                alt="image" />
              </div>
              <div className='payment-tripsumcont'>
                <h5>{courses?.golf_course_name}</h5>
                {/* <p className='pay-triphotel'>3 Day/4 Nights Stay at Melbourne</p> */}
                {/* <p>Deluxe Twin Bed Room | Apr 10, 11:30am</p> */}
                <p>No of Golfers - {courses?.total_qolfers}</p>
                <p>Date - {courses?.date} </p>
                <p>Tea Time - {courses?.tee_time} </p>
                {/* <p className='pay-tripsum-total'><span>Price</span>USD {courses?.golf_course_price}/Per Person</p> */}
              </div>
              </>
            
            </div>
        ))}
        </>
    )
}
export default Course