import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../Layout';
import { useLocation, useNavigate } from 'react-router-dom';
import GolfHolidaysList from './GolfHolidaysList';
import {getGolfResults } from '../../Redux/actions/bookingActions'
import { BOOKING_DATA , LOADING , GOLFCOURSELOADING } from '../../Redux/constants/bookingConstants'
import { Dataset } from '@mui/icons-material';

const SearchResult = () => {

    const navigate = useNavigate()
    const [details, setDetails] = useState({'title':'Golfcourses -Golftripz','meta_desc':'Golfcourses list','meta_keyword':'services , golftripz , golfcourses'})
    const [datas, setDatas] = useState(localStorage.getItem("booking"))
    const dispatch = useDispatch();
    const { availableCourses }  = useSelector(state => state.booking)
    const [golfCourses, setGolfCourses] = useState(availableCourses);
    const { userSearchData }  = useSelector(state => state.home)




    useEffect(() => {
        localStorage.removeItem("booking");
    }, []);
    
    useEffect(() => {
        dispatch({
            type: LOADING,
            payload: true
        })
        if (userSearchData && userSearchData !== '' && userSearchData?.type ==='course') {
            getGolfCourseData2()
        } 
        else{
        getGolfCourseData()

        }
      }, [userSearchData]);


      const getGolfCourseData = async() => {
        dispatch({
            type: GOLFCOURSELOADING,
            payload: false
        })

        var search = JSON.parse(localStorage.getItem("search"));	
      
        if(search ===null){
           
            var data = {
                'search':"",
                'date':new Date(new Date()).toISOString().split('T')[0]
            }
            dispatch({
                type: BOOKING_DATA,
                payload: data
            })
            await dispatch(getGolfResults(data));
           
        }
        else{
            const ApiData = {
                'search':search.search,
                'date':new Date(search.date).toISOString().split('T')[0]
            }
            
            dispatch({
                type: BOOKING_DATA,
                payload: ApiData
            })
            await  dispatch(getGolfResults(ApiData));
        }
        // dispatch({
        //     type: GOLFCOURSELOADING,
        //     payload: false
        // })
       
    }

    const getGolfCourseData2 = async() => {
        dispatch({
            type: LOADING,
            payload: true
        })
        const holidaySearch = userSearchData?.search;
        const holidayDate = userSearchData?.date;
        const data = {
            search: holidaySearch,
            date: holidayDate
        };
        const Apidata = {
            search: holidaySearch,
            date: new Date(holidayDate).toISOString().split('T')[0]
        };
        localStorage.setItem("search", JSON.stringify(data));
             dispatch({
                 type: BOOKING_DATA,
                 payload: data
             })
             await  dispatch(getGolfResults(Apidata));
            //  dispatch({
            //     type: GOLFCOURSELOADING,
            //     payload: false
            // })
     }


    return (
        <Layout head={"SearchResult"} details={details}>
            <GolfHolidaysList/>   
        </Layout>
    )
}

export default SearchResult
