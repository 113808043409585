import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../Layout';
import GolfEventsView from './GolfEventsView';
import { BOOKING_DATA } from '../../Redux/constants/bookingConstants'
import {getGolfHolidayResults } from '../../Redux/actions/bookingActions'
const GolfEventsList = () => {
     const [details, setDetails] = useState({'title':'Golftripz Events','meta_desc':'Golftripz Events','meta_keyword':'golftripz , events'})
     const dispatch = useDispatch();
     useEffect(() => {
         getevents()
        
      }, []);
      
      const getevents=async()=>{
        let bookinData = JSON.parse(localStorage.getItem("holidaysearch"));
        const ApiData = {
            'search':bookinData.holidaysearch,
            'date':new Date(bookinData.holidaystartdate).toISOString().split('T')[0]
        }
         dispatch({
             type: BOOKING_DATA,
             payload: ApiData
         })
         await  dispatch(getGolfHolidayResults(ApiData));
      }
    return (
        <Layout head={"GolfEvents List"} details={details}>
            <GolfEventsView/>
        </Layout>
    )
}

export default GolfEventsList;
