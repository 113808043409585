import React, { useEffect, useState } from 'react'
import { Grid, Typography, Box, Button } from '@mui/material'
import profile from '../../assets/images/profile.png'
import Slider from "react-slick";
import moment from 'moment';
import { faArrowLeft, faArrowRight, faStar, faLessThan, faGreaterThan, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import rectangle1 from '../../assets/images/Rectangle 18.png'
import Noimage from "../../assets/images/no-img.png";

const Reviews = ({ golf_holiday }) => {

    
 // --------------------slider------------------------------
 var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
// ---------------------slider-------------------------------
const [reviewlist ,setReview] = useState([])
useEffect(() => {
   
    setReview(golf_holiday?.golf_holidays?.reviews)
}, [])
    return(
     
      <div className='final-de-reviewcon'>
         { reviewlist?.length >0 ?
        reviewlist.slice(0, 5).map((review,i) => (
      <div className="review-section">
        <div className="review-header">
          <div className="review-title">Reviews</div>
          <div className="review-rating">
            <p className="rating-badge"><span><FontAwesomeIcon icon={faStar} /></span> 4.8</p>
          </div>
        </div>
        <div className="review-card">
          <div className="reviewer-info">
            <div className='final-deheader'>
              <div className='final-review-imgcircle'>
                <img src={review?.file ? review?.file : rectangle1} alt="Profile" className="reviewer-image" onError={(e) => {e.target.onerror = null;e.target.src = Noimage; }} />
              </div>
              <div>
                <h3 className="reviewer-name">{review.name}</h3>
                <div className='reviewer-details'>
                  <p className="reviewer-location">Kuala Lumpur, Malaysia</p>
                  <p className="review-date">{moment(review.date, "DD-MM-YYYY").format("MMM Do YYYY")}</p>
                </div>
              </div>
            </div>

            <div className="review-rating-stars">
              <FontAwesomeIcon icon={faStar} className="star-icon" />
              <FontAwesomeIcon icon={faStar} className="star-icon" />
              <FontAwesomeIcon icon={faStar} className="star-icon" />
              <FontAwesomeIcon icon={faStar} className="star-icon" />
              <FontAwesomeIcon icon={faStar} className="star-icon" />
            </div>
          </div>
          <div className="review-content">
            <h4>Excellent service...</h4>
            <p>
            {review.message}
            </p>
          </div>
        </div>
      </div>
     )) 
     :"No Reviews Yet!"
    }
     
    </div>
    
   
  
   
    )
}
export default Reviews;