import React, { useState, useEffect,useRef} from 'react';
import Noimage from "../../assets/images/no-img.png";
const Holiday=({datas})=>{

    const [courseData, setCourses] = useState([]);
    const [hotelData, setHotels] = useState([]);
    const [serviceData, setServices] = useState([]);
    const [transferData, setTransfer] = useState([]);

    useEffect(()=>{
      setCourses(datas?.golf_holiday?.selected_course?.filter(item =>
        item.status !== 5
                ))
        // setCourses(datas?.golf_holiday?.selected_course)
        setHotels(datas?.golf_holiday?.selected_hotel?.filter(item =>
          item.status !== 5
                  ))
        setServices(datas?.golf_holiday?.selected_service?.filter(item =>
          item.status !== 5
                  ))
        setTransfer(datas?.golf_holiday?.selected_transfer?.filter(item =>
          item.status !== 5
                  ))
    },[])

    return(
        <>
        <h3>Courses</h3>
        {courseData?.map((courses,index)=>(
        <div className='pay-tripsummary-details' key={index}>
              <div className='payment-tripsumimg'>
                <img 
                onError={(e) => {
                  e.target.onerror = null; // Prevent infinite loop
                  e.target.src = Noimage; // Replace with default image if original image fails to load
                }}
                src={courses?.image} alt="image" />
              </div>
              <div className='payment-tripsumcont'>
                <h5>{courses?.golf_course_name}</h5>
                {/* <p className='pay-triphotel'>3 Day/4 Nights Stay at Melbourne</p> */}
                <p>No Of Golfers - {courses?.no_of_golfers}</p>
                {/* <p>Tea Time - {courses?.tee_time} </p>
                <p className='pay-tripsum-total'><span>Price of Golfer</span>USD {courses?.price_of_golfers}/Per Person</p> */}
              </div>
        </div>
         ))}
          <h3>Hotels</h3>
        {hotelData?.map((hotel,index)=>(
        <div className='pay-tripsummary-details' key={index}>
              <div className='payment-tripsumimg'>
                <img 
                onError={(e) => {
                  e.target.onerror = null; // Prevent infinite loop
                  e.target.src = Noimage; // Replace with default image if original image fails to load
                }}
                src={hotel?.image} alt="image" />
              </div>
              <div className='payment-tripsumcont'>
                <h5>{hotel?.hotel_name}</h5>
                <p className='pay-triphotel'>{hotel?.adults} Adults ,{hotel?.child} Childrens Stay at {hotel?.hotel_name}</p>
                <p>Check-in-date - {hotel?.check_in_date}    </p>
                <p>Check-out-date - {hotel?.check_out_date} </p>
                {/* <p className='pay-tripsum-total'><span>Price for Room</span>USD {hotel?.total_price}/Per Room</p> */}
              </div>
        </div>
         ))}
        
          <h3>Transfer</h3>
        {transferData?.map((transfer,index)=>(
        <div className='pay-tripsummary-details' key={index}>
              <div className='payment-tripsumimg'>
                <img
                onError={(e) => {
                  e.target.onerror = null; // Prevent infinite loop
                  e.target.src = Noimage; // Replace with default image if original image fails to load
                }}
                src={transfer?.image ? transfer?.image : Noimage} alt="image" />
              </div>
              <div className='payment-tripsumcont'>
                <h5>{transfer?.name_of_transfer}</h5>
                <p className='pay-triphotel'>{transfer?.adults} Adults ,{transfer?.child} Childrens Stay at {transfer?.hotel_name}</p>
                
                {/* <p className='pay-tripsum-total'><span>Price for Room</span>USD {transfer?.total_price}/Per Room</p> */}
              </div>
        </div>
         ))}

        <h3>Services</h3>
        {serviceData?.map((service,index)=>(
        <div className='pay-tripsummary-details' key={index}>
              <div className='payment-tripsumimg'>
                <img 
                onError={(e) => {
                  e.target.onerror = null; // Prevent infinite loop
                  e.target.src = Noimage; // Replace with default image if original image fails to load
                }}
                src={service?.image ? service?.image : Noimage} alt="image" />
              </div>
              <div className='payment-tripsumcont'>
                <h5>{service?.service_name}</h5>
                <p className='pay-triphotel'>{service?.adults} Adults ,{service?.child} Childrens Stay at {service?.hotel_name}</p>
                
                
              </div>
        </div>
         ))}
         </>
    )
}
export default Holiday