import React, {useState , useEffect} from 'react'
import {useSelector } from 'react-redux'
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination,
     Modal, Box, Badge
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye,  faTimes ,faDownload   ,faUser} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const MyBookingLists = () => {
     
    const [bookingHistory,setBookingHitory ] = useState([])
    // const { bookingHistory } = useSelector(state => state.booking)
    const [selectedRow, setSelectedRow] = useState(null);
    const [open, setOpen] = useState(false);
    const [holidayopen, setHolidayOpen] = useState(false);
    const [selectedHolidayRow, setSelectedHolidayRow] = useState(null);
    const [instantopen, setIpOpen] = useState(null);
    const [selectedIpRow, setSelectedIpRow] = useState(null);
    const [holidayItineryDay, setHolidayItineryDay] = useState([]);
    const [page, setPage] = useState(0);
    const rowsPerPage = 10;
    const { tripLists } = useSelector(state => state.trip)
    const { holidayLists } = useSelector(state => state.trip)
    const { ipLists, deleteSuccess, error } = useSelector(state => state.trip)
    const [bookingType,setBookingType] = useState(null)
    const [gatewaylist, setGateways] = useState([]);
    useEffect(() => {
        
        var lists=[...tripLists,...holidayLists,...ipLists]
        let sortedLists = lists.sort((a, b) => {
            let dateA = new Date(a.created_at);
            let dateB = new Date(b.created_at);
            return dateB - dateA;
        });
        setBookingHitory(sortedLists)
    }, []);

    const handleCloseHoliday = () => {
        setHolidayOpen(false);
        setSelectedHolidayRow(null);
    };
    const handleCloseIP = () => {
        setIpOpen(false);
        setSelectedIpRow(null);
    };

    const handleOpen = (row) => {
        // setSelectedRow(row);
        // setOpen(true);
        var type=row?.booking_ref_number.split('-');
        // alert(type[0]);
        if(type[0] === "GTGC"){
            setSelectedRow(row);
            setOpen(true);
        } else if(type[0]==="GTGH"){

            setSelectedHolidayRow(row);
            setHolidayOpen(true);
            const days = [];
            row.selected_course.map((course) => {
                if (!days.includes(course.day))
                    days.push(course.day)
            })
            row.selected_hotel.map((hotel) => {
                if (!days.includes(hotel.day))
                    days.push(hotel.day)
            })
            row.selected_service.map((service) => {
                if (!days.includes(service.day))
                    days.push(service.day)
            })
            row.selected_transfer.map((transfer) => {
                if (!days.includes(transfer.day))
                    days.push(transfer.day)
            })
            days.sort((a, b) => Number(a) - Number(b));
            setHolidayItineryDay(days)
        }else if(type[0] ==="GTIP"){
            setSelectedIpRow(row);
            setIpOpen(true);
        }
            var gateways=[]
            row?.transaction_details?.map(item =>{
                gateways.push(item?.payment_gateway_name)
            })
            const uniqueGateways = Array.from(new Set(gateways));
            setGateways(uniqueGateways)
    };
    const getBackgroundColor = (status) => {
        switch (status) {
            case 'Pending':
                return '#684B2A';
            case 'Paid':
                return '#598B28';
            default:
                return '#000000';
        }
    };
    const handleClose = () => {
        setOpen(false);
        setSelectedRow(null);
    };

    
    const getFormattedPhone = (number) => {
        const phoneNumberObj = parsePhoneNumberFromString(`+${number}`);
        
        if (phoneNumberObj) {
            const countryCode = phoneNumberObj.countryCallingCode;
            const mobileNumber = phoneNumberObj.nationalNumber;
            return `+${countryCode} ${mobileNumber}`;
        } else {
            return number;
        }
    };

    const DownloadPdf = (url) => {
      
        var link = document.createElement('a');
                link.href = url;
                link.target = '_blank'
                link.setAttribute('download', 'invoice.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
    }

    const calculateBasePrice = () => {
        var baseprice = 0;
        var coursebaseprice = selectedRow?.details.map(item => {
           if(item?.status === 1 || item?.status === 2 || item?.status === 3 ){
            baseprice += parseFloat(item.golf_course_price) * parseInt(item.total_qolfers);
           }
        });
        return baseprice?.toFixed(2);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const getBookingType=(bookingId)=>{
        if(bookingId && bookingId.length > 0){

        var type=bookingId.split('-');
        if(type[0]==="GTGC"){
            var bookingtype= "Golf Course"
        } else if(type[0]==="GTGH"){
            var bookingtype="Golf Holiday";
        }else if(type[0]==="GTIP"){
            var bookingtype ="Instant Package";
        }
        // setBookingType(bookingtype)
        return bookingtype;
    }
    }
    return(
        <div className='mybook-con'>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow sx={{ backgroundColor: 'green' }} className='book-tr-th'>
                                <TableCell sx={{ color: 'white' }}>S.No</TableCell>
                                <TableCell sx={{ color: 'white' }}>Booking Id</TableCell>
                                <TableCell sx={{ color: 'white' }}>Booking Date</TableCell>
                                <TableCell sx={{ color: 'white' }}>Booking Type</TableCell>
                                <TableCell sx={{ color: 'white' }}>Customer Name</TableCell>
                                <TableCell sx={{ color: 'white' }}>Customer Email</TableCell>
                                <TableCell sx={{ color: 'white' }}>Customer Phone</TableCell>
                                <TableCell sx={{ color: 'white' }}>Status</TableCell>
                                <TableCell sx={{ color: 'white' }}>Payment</TableCell>
                                <TableCell sx={{ color: 'white' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        
                        {bookingHistory && bookingHistory.length > 0 && (
                             <TableBody>
                             {bookingHistory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                 <TableRow
                                 key={index}
                                 sx={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }} className='book-tr-td'
                             >
                                <TableCell className='book-td-grey'>{index+1}</TableCell>
                                <TableCell className='book-td-grey'>{row?.booking_ref_number}</TableCell>
                                <TableCell className='book-td-grey'>{moment(row.created_at).format("DD-MM-YYYY")}</TableCell>
                                <TableCell className='book-td-grey'>{getBookingType(row?.booking_ref_number)}</TableCell>
                                <TableCell className='book-td-grey'>{row?.customer_name}</TableCell>
                                <TableCell className='book-td-grey'>{row?.customer_email}</TableCell>
                                <TableCell className='book-td-grey'>{row?.customer_phone}</TableCell>
                                <TableCell className='book-td-grey'>
                                <span style={{ color: 'primary' }}>
                                        {row.status === 1
                                            ?<Badge className='badge-format unconformed'  badgeContent="Unconfirmed">
                                           
                                          </Badge>
                                            : row.status === 2
                                                ? <Badge className='badge-format p-conformed' badgeContent="Partially Confirmed">
                                              </Badge>
                                                : row.status === 3
                                                    ? <Badge className='badge-format conformed'  badgeContent="Confirmed"></Badge>
                                                    : <Badge className='badge-format cancelled'  badgeContent="Cancelled"></Badge>}
                                    </span>
                                </TableCell>
                                <TableCell >
                                    <span style={{color:"blue"}}>
                                        {row.payment_status === 1 ? (
                                            <Badge className="badge-format unconformed"  badgeContent="Pending" />
                                        ) : row.payment_status === 2 ? (
                                            <Badge className="badge-format p-conformed"  badgeContent="Partially Paid" />
                                        ) : row.payment_status === 3 ? (
                                            <Badge className="badge-format conformed" badgeContent="Paid" />
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </TableCell>
                                <TableCell style={{ cursor: "pointer" }}className='book-td-green' sx={{ whiteSpace: 'nowrap' }} onClick={() => handleOpen(row)}>
                                    <FontAwesomeIcon icon={faEye}  />{row.actions}
                                </TableCell>
                                 </TableRow>
                             ))}
                         </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={bookingHistory?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                />
            </Paper>

        {/* COURSE MODEL */}
        <Modal open={open} onClose={handleClose}>
            <div className='dashboard-popup-box'>
                <Box className="modal-box">
                    {selectedRow && (
                        <>
                            <h2 className="modal-header">Booking History</h2>
                            <button className="close-button" onClick={handleClose}>
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                            <div className="modal-content">
                                <div className="modal-section">
                                    <p><strong>Name:</strong> {selectedRow?.details[0]?.lead_golfer_name || 'N/A'}</p>
                                    <p><strong>Nationality:</strong> {selectedRow?.details[0]?.customer_nationality || ''}</p>
                                </div>
                                <div className="modal-section">
                                    <p style={{'textTransform' : 'unset'}}><strong>Email:</strong> {selectedRow?.details[0]?.customer_email || 'N/A'}</p>
                                  
                                </div>
                                <div className="modal-section">
                                    <p><strong>Mobile No:</strong> {getFormattedPhone(selectedRow?.details[0]?.customer_phone) || 'N/A'}</p>
                                    <p><strong>No of Golfers:</strong> {selectedRow?.details?.filter(prodData => prodData.status !== 5).map(prodData => prodData.total_qolfers).join(' , ') || ''}</p>

                                </div>
                                <div className="modal-section">
                                    <p><strong>Booking Ref No:</strong> {selectedRow?.booking_ref_number || ''}</p>
                                </div>
                                <div className="modal-section">
                                    <p><strong>Remark:</strong> {selectedRow?.details[0]?.customer_remarks || ''}</p>

                                </div>
                            </div>
                            <div className="modal-details">
                                <h3>Booking Details</h3>
                                <TableContainer sx={{ maxHeight: 440 }} className='popup-dash-details'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: 'green' }} className='book-tr-th'>
                                                <TableCell sx={{ color: 'white' }}>Course ID</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Booking Confirmation No</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Booking Status</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Date</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Tee Time</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Course Name</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Booked By</TableCell>
                                                <TableCell sx={{ color: 'white' }}>No of Golfers</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Golf Price</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Total Price</TableCell>
                                                
                                              
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {selectedRow?.details?.map((course) => 
                                            course?.status !== 5 ? (
                                            <TableRow key={course?.id}>
                                                <TableCell>{course?.id || 'N/A'}</TableCell>
                                                <TableCell>{course?.booking_confirmation_number}</TableCell>
                                                <TableCell>
                                                <span style={{ color: 'blue' }}> {/* Change 'blue' to your primary color */}
                                                    {course.status === 1 ? (
                                                    <Badge className="badge-format unconformed" badgeContent="Unconfirmed" />
                                                    ) : course.status === 2 ? (
                                                    <Badge className="badge-format p-conformed" badgeContent="Partially Confirmed" />
                                                    ) : course.status === 3 ? (
                                                    <Badge className="badge-format conformed" badgeContent="Confirmed" />
                                                    ) : (
                                                    <Badge className="badge-format cancelled" badgeContent="Cancelled" />
                                                    )}
                                                </span>
                                                </TableCell>
                                                <TableCell>{moment(course?.date).format("DD-MM-YYYY")}</TableCell>
                                                <TableCell>{course?.tee_time}</TableCell>
                                                <TableCell>{course?.golf_course_name}</TableCell>
                                                <TableCell>{course?.lead_golfer_name}</TableCell>
                                                <TableCell>{course?.total_qolfers}</TableCell> {/* Fixed spelling mistake */}
                                                <TableCell>{selectedRow?.currency} {course?.golf_course_price?.toFixed(2)}</TableCell>
                                                <TableCell>{selectedRow?.currency} {(course?.golf_course_price * course?.total_qolfers).toFixed(2)}</TableCell>
                                            </TableRow>
                                            ) : null
                                        )}
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                                
                                <h3>Invoice Details</h3>
                                <TableContainer sx={{ maxHeight: 440 }} className='popup-dash-details'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: 'green' }} className='book-tr-th'>
                                                <TableCell sx={{ color: 'white' }}>S.no</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Booking Reference No</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Invoice No</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Invoice Date</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Service Type</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Customer Name</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Email</TableCell>
                                              
                                                <TableCell sx={{ color: 'white' }}>Invoice Price</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Total Price</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Invoice</TableCell>
                                                
                                              
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedRow?.payment_details?.length >0 ?selectedRow?.payment_details?.map((invoice ,index) => (
                                                <TableRow>
                                                    <TableCell>{index +1}</TableCell>
                                                   
                                                    <TableCell>{invoice?.booking_reference_number}</TableCell>
                                                   
                                                    <TableCell>{invoice?.invoice_number}</TableCell>
                                                    <TableCell>{moment(invoice?.invoice_date).format("DD-MM-YYYY")}</TableCell>
                                                    <TableCell>{invoice?.service_type}</TableCell>
                                                    <TableCell>{invoice?.customer_name}</TableCell>
                                                    <TableCell>{invoice?.customer_email}</TableCell>
                                                    <TableCell>{selectedRow?.currency} {invoice?.invoice_price?.toFixed(2)}</TableCell>
                                                    <TableCell>{selectedRow?.currency} {invoice?.total_price?.toFixed(2)}</TableCell>
                                                    <TableCell>
                                                    <FontAwesomeIcon style={{ cursor: "pointer" }} onClick={() => DownloadPdf(invoice?.invoice_pdf)} icon={faDownload} />
                                                    {' '}
                                                    {invoice?.payment_link && invoice?.payment_link !== '' && (
                                                        <a target='_blank' href={invoice?.payment_link}>Pay</a>
                                                    )}
                                                    </TableCell>
                                                   
                                                   
                                                   
                                                   
                                                </TableRow>
                                            )):
                                            <TableRow>
                                            <TableCell colSpan={10} style={{ textAlign: 'center' }}>No Invoice Informations found</TableCell>
                                        </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <h3>Transaction Details</h3>
                                <TableContainer sx={{ maxHeight: 440 }} className='popup-dash-details'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: 'green' }} className='book-tr-th'>
                                                <TableCell sx={{ color: 'white' }}>S.no</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Invoice Number</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Transaction Ref ID</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Transaction Date	</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Payment Gateway</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Transaction Price</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Currency </TableCell>
                                                {/* <TableCell sx={{ color: 'white' }}>payment_remarks</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Action</TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedRow?.transaction_details?.length >0 ?
                                            // selectedRow?.transaction_details?.map((transaction ,index) => (
                                                <TableRow>
                                                    <TableCell>1</TableCell>
                                                    <TableCell>{selectedRow?.transaction_details?.map(item => item.invoice_number).join(', ')}</TableCell>
                                                    <TableCell>{selectedRow?.transaction_details?.map(item => item.transaction_reference_number).join(', ')}</TableCell>
                                                  
                                                    <TableCell>{selectedRow?.transaction_details?.map(item => moment(item.transaction_date).format('DD-MM-YYYY')).join(', ')}</TableCell>
                                                    <TableCell style={{textTransform:"capitalize"}}>{gatewaylist?.map(item => item).join(', ')}</TableCell>
                                                    <TableCell>{selectedRow?.payment_details[0]?.paid_amount.toFixed(2)}</TableCell>
                                                    <TableCell>{selectedRow?.payment_details[0].currency}</TableCell>
                                                    {/* <TableCell>{transaction?.payment_remarks}</TableCell> */}
                                                    {/* <TableCell style={{cursor: "pointer"}} onClick={()=>DownloadPdf(transaction?.receipt_pdf)}> <FontAwesomeIcon icon={faDownload}  /></TableCell> */} 
                                                </TableRow>
                                            :
                                            <TableRow>
                                                <TableCell colSpan={9} style={{ textAlign: 'center' }}>No Transaction Information found</TableCell>
                                            </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div className='myacc-floatright'>
                                    <h4>Fare Details</h4>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th colSpan="4">Base Price</th>
                                                {/* <td>${selectedRow?.details?.reduce((sum, prod) => sum + prod.total_price, 0) || 'N/A'}</td> */}
                                                <td style={{"textAlign":"right"}}>{selectedRow?.currency} {calculateBasePrice()}</td>
                                            </tr>
                                            {/* <tr>
                                                <th>Convenience Fee</th>
                                                <td>{currencyCode} {calculateConvineanceFeeTotal()}</td>
                                            </tr> */}
                                            <tr>
                                                <th colSpan="4">Total</th>
                                                {/* <td>{currencyCode} {(parseInt(calculateBasePrice()) + parseInt(calculateConvineanceFeeTotal()))?.toFixed(2)}</td> */}
                                                <td style={{"textAlign":"right"}}>{selectedRow?.currency} {calculateBasePrice()}</td>
                                            </tr>
                                            {selectedRow?.transaction_details && selectedRow?.transaction_details?.length >0 && (
                                            <tr>
                                                <th colSpan="4">Paid Price</th>
                                                <td style={{"textAlign":"right"}}>{selectedRow?.currency} {selectedRow?.transaction_details.reduce((total, detail) => total + detail?.transaction_price, 0).toFixed(2)}</td>
                                            </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    )}
                </Box>
            </div>
        </Modal>
        {/* COURSE MODAL END */}
        {/* HOLIDAY MODAL*/}
        <Modal open={holidayopen} onClose={handleCloseHoliday}>
            <div className='dashboard-popup-box'>
                <Box className="modal-box">
                    {selectedHolidayRow && (
                        <>
                            <h2 className="modal-header">Booking History</h2>
                            <button className="close-button" onClick={handleCloseHoliday}>
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                            <div className="modal-content">
                                <div className="modal-section">
                                    <p><strong>Name:</strong> {selectedHolidayRow?.customer_name || ''}</p>
                                    <p><strong>Nationality:</strong> {selectedHolidayRow?.customer_nationality || ''}</p>
                                    <p><strong>Remarks:</strong> {selectedHolidayRow?.customer_remarks || ''}</p>
                                </div>
                                <div className="modal-section">
                                    <p><strong>Mobile No:</strong> {getFormattedPhone(selectedHolidayRow?.customer_phone)}</p>
                                    <p><strong>No of Golfers:</strong> {selectedHolidayRow?.no_of_golfers || ''}</p>
                                </div>
                                <div className="modal-section">
                                    <p style={{ textTransform: 'unset' }}><strong>Email:</strong> {selectedHolidayRow?.customer_email || ''}</p>
                                    <p><strong>No of Non Golfers:</strong> {selectedHolidayRow?.no_of_non_golfers || ''}</p>
                                </div>
                            </div>
                            <div className="modal-details">
                                <h3>Booking Details</h3>
                                <TableContainer sx={{ maxHeight: 440 }} className='popup-dash-details'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: 'green' }} className='book-tr-th'>
                                                <TableCell sx={{ color: 'white' }}>Holiday ID</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Date</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Holiday Name</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Booked By</TableCell>
                                                <TableCell sx={{ color: 'white' }}>No of Golfers</TableCell>
                                                <TableCell sx={{ color: 'white' }}>No of Non Golfers</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Price</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Payment Status</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Booking Status</TableCell>
                                                {/* <TableCell sx={{ color: 'white' }}>Payment Status</TableCell> */}
                                                {/* <TableCell sx={{ color: 'white' }}>Booking Status</TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>{selectedHolidayRow?.booking_ref_number || 'N/A'}</TableCell>
                                                <TableCell>{moment(selectedHolidayRow?.date).format("DD-MM-YYYY")}</TableCell>
                                                <TableCell>{selectedHolidayRow?.golf_holiday_name}</TableCell>
                                                <TableCell>{selectedHolidayRow?.customer_name}</TableCell>
                                                <TableCell>{selectedHolidayRow?.no_of_golfers}</TableCell>
                                                <TableCell>{selectedHolidayRow?.no_of_non_golfers}</TableCell>
                                                <TableCell>{selectedHolidayRow?.currency} {selectedHolidayRow?.total_price.toFixed(2)}</TableCell>
                                                <TableCell style={{ color: getBackgroundColor(selectedHolidayRow.status), whiteSpace: 'nowrap' }}>
                                                {selectedHolidayRow.payment_status === 1 ? (
                                                <Badge className="badge-format unconformed"   badgeContent="Pending" />
                                                ) : selectedHolidayRow.payment_status === 2 ? (
                                                <Badge className="badge-format p-conformed"  badgeContent="Partially Paid" />
                                                ) : selectedHolidayRow.payment_status === 3 ? (
                                                <Badge className="badge-format conformed"  badgeContent="Paid" />
                                                ) : (
                                                <Badge className="badge-format cancelled"  badgeContent="Cancelled" />
                                                )}
                                                </TableCell>


                                                <TableCell className='book-td-green' sx={{ whiteSpace: 'nowrap' }}>
                                                <span style={{ color: 'primary' }}>
                                                        {selectedHolidayRow.status === 1 ? (
                                                            <Badge className="badge-format unconformed" badgeContent="Unconfirmed" />
                                                        ) : selectedHolidayRow.status === 2 ? (
                                                            <Badge className="badge-format p-conformed" badgeContent="Partially Confirmed" />
                                                        ) : selectedHolidayRow.status === 3 ? (
                                                            <Badge className="badge-format conformed"  badgeContent="Confirmed" />
                                                        ) : (
                                                            <Badge className="badge-format cancelled"  badgeContent="Cancelled" />
                                                        )}
                                                    </span>

                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            
                                {holidayItineryDay.length > 0 && (
                                    holidayItineryDay.map((day, index) => {
                                        // Find the corresponding items for the current day
                                        const course = selectedHolidayRow.selected_course.find(course => course.day === day && course?.status !== 5);
                                        const hotel = selectedHolidayRow.selected_hotel.find(hotel => hotel.day === day && hotel?.status !== 5);
                                        const transfer = selectedHolidayRow.selected_transfer.find(transfer => transfer.day === day && transfer?.status !== 5);
                                        const service = selectedHolidayRow.selected_service.find(service => service.day === day && service?.status !== 5);

                                        return (
                                            <div>
                                                <p className='day-btn'>Day {day}</p>
                                                <table key={index} style={{ width: '100%', borderCollapse: 'collapse' }} className='day-tablecontent'>
                                                    <thead>
                                                    <tr>
                                                        {course && (
                                                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Selected Course</th>
                                                        )}
                                                        {hotel && (
                                                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Selected Hotel</th>
                                                        )}
                                                        {transfer && (
                                                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Selected Transfer</th>
                                                        )}
                                                        {service && (
                                                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Selected Service</th>
                                                        )}
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                        {course && (
                                                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                                       <div className='details-row-ins'>
                                                            <h5><b>{course ? course.golf_course_name : 'N/A'}</b></h5>
                                                            <p>Status:
                                                            {course.status === 1 ? (
                                                            <Badge className="badge-format unconformed" badgeContent="Unconfirmed" />
                                                        ) : course.status === 2 ? (
                                                            <Badge className="badge-format p-conformed" badgeContent="Partially Confirmed" />
                                                        ) : course.status === 3 ? (
                                                            <Badge className="badge-format conformed"  badgeContent="Confirmed" />
                                                        ) : (
                                                            <Badge className="badge-format cancelled"  badgeContent="Cancelled" />
                                                        )}
                                                            </p>
                                                            <p>confirmation number:<span>{course?.booking_confirmation_number}</span></p>
                                                             <p>Description: <div dangerouslySetInnerHTML={{ __html: course ? course.description : 'N/A' }} /></p>
                                                       </div>
                                                    </td>
                                                        )}
                                                        {hotel && (
                                                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                                       
                                                        <div className='details-row-ins'>
                                                            <h5><b>{hotel ? hotel.hotel_name : 'N/A'}</b></h5>
                                                            <p>Status:
                                                            {hotel.status === 1 ? (
                                                            <Badge className="badge-format unconformed" badgeContent="Unconfirmed" />
                                                        ) : hotel.status === 2 ? (
                                                            <Badge className="badge-format p-conformed" badgeContent="Partially Confirmed" />
                                                        ) : hotel.status === 3 ? (
                                                            <Badge className="badge-format conformed"  badgeContent="Confirmed" />
                                                        ) : (
                                                            <Badge className="badge-format cancelled"  badgeContent="Cancelled" />
                                                        )}
                                                       </p>
                                                            <p>confirmation number:<span>{hotel?.booking_confirmation_number}</span></p>
                                                             <p>Description: <div dangerouslySetInnerHTML={{ __html: hotel ? hotel.hotel_description : 'N/A' }} /></p>
                                                
                                                       </div>
                                                    </td>
                                                        )}
                                                        {transfer && (
                                                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                                       
                                                       <div className='details-row-ins'>
                                                            <h5><b>{transfer ? transfer.name_of_transfer : 'N/A'}</b></h5>
                                                            <p>Status:
                                                            {transfer.status === 1 ? (
                                                            <Badge className="badge-format unconformed" badgeContent="Unconfirmed" />
                                                        ) : transfer.status === 2 ? (
                                                            <Badge className="badge-format p-conformed" badgeContent="Partially Confirmed" />
                                                        ) : transfer.status === 3 ? (
                                                            <Badge className="badge-format conformed"  badgeContent="Confirmed" />
                                                        ) : (
                                                            <Badge className="badge-format cancelled"  badgeContent="Cancelled" />
                                                        )}
                                                            </p>
                                                            <p>confirmation number:<span>{transfer?.booking_confirmation_number}</span></p>
                                                             <p>Description: <div dangerouslySetInnerHTML={{ __html: transfer ? transfer.description : 'N/A' }} /></p>
                                                       </div>
                                                    </td>
                                                        )}
                                                        {service && (
                                                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                                        
                                                        <div className='details-row-ins'>
                                                        <p>Status:
                                                        {service.status === 1 ? (
                                                            <Badge className="badge-format unconformed" badgeContent="Unconfirmed" />
                                                        ) : service.status === 2 ? (
                                                            <Badge className="badge-format p-conformed" badgeContent="Partially Confirmed" />
                                                        ) : service.status === 3 ? (
                                                            <Badge className="badge-format conformed"  badgeContent="Confirmed" />
                                                        ) : (
                                                            <Badge className="badge-format cancelled"  badgeContent="Cancelled" />
                                                        )}
                                                            </p>
                                                            <p>confirmation number:<span>{service?.booking_confirmation_number}</span></p>
                                                             <p>Description: <div dangerouslySetInnerHTML={{ __html: service ? service.service_description : 'N/A' }} /></p>
                                                        </div>
                                                    </td>
                                                        )}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        );
                                    })
                                )}
                                
                              
                                <h3>Invoice Details</h3>
                                <TableContainer sx={{ maxHeight: 440 }} className='popup-dash-details'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: 'green' }} className='book-tr-th'>
                                                <TableCell sx={{ color: 'white' }}>S.no</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Booking Reference No</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Invoice No</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Invoice Date</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Service Type</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Customer Name</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Email</TableCell>
                                              
                                                <TableCell sx={{ color: 'white' }}>Invoice Price</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Total Price</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Invoice</TableCell>
                                                
                                              
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedHolidayRow?.payment_details?.length >0 ?selectedHolidayRow?.payment_details?.map((invoice ,index) => (
                                                <TableRow>
                                                    <TableCell>{index +1}</TableCell>
                                                   
                                                    <TableCell>{invoice?.booking_reference_number}</TableCell>
                                                   
                                                    <TableCell>{invoice?.invoice_number}</TableCell>
                                                    <TableCell>{moment(invoice?.invoice_date).format("DD-MM-YYYY")}</TableCell>
                                                    <TableCell>{invoice?.service_type}</TableCell>
                                                    <TableCell>{invoice?.customer_name}</TableCell>
                                                    <TableCell>{invoice?.customer_email}</TableCell>
                                                    <TableCell>{selectedHolidayRow?.currency} {invoice?.invoice_price?.toFixed(2)}</TableCell>
                                                    <TableCell>{selectedHolidayRow?.currency} {invoice?.total_price?.toFixed(2)}</TableCell>
                                                    <TableCell>
                                                    <FontAwesomeIcon style={{ cursor: "pointer" }} onClick={() => DownloadPdf(invoice?.invoice_pdf)} icon={faDownload} />
                                                    {' '}
                                                    {invoice?.payment_link && invoice?.payment_link !== '' && (
                                                        <a target='_blank' href={invoice?.payment_link}>Pay</a>
                                                    )}
                                                    </TableCell>
                                                   
                                                   
                                                   
                                                   
                                                </TableRow>
                                            )):
                                            <TableRow>
                                            <TableCell colSpan={10} style={{ textAlign: 'center' }}>No Invoice Informations found</TableCell>
                                        </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <h3>Transaction Details</h3>
                                <TableContainer sx={{ maxHeight: 440 }} className='popup-dash-details'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: 'green' }} className='book-tr-th'>
                                                <TableCell sx={{ color: 'white' }}>S.no</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Invoice Number</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Transaction Ref ID</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Transaction Date	</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Payment Gateway</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Paid Amount</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Currency</TableCell>
                                                {/* <TableCell sx={{ color: 'white' }}>payment_remarks</TableCell> */}
                                                {/* <TableCell sx={{ color: 'white' }}>Action</TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                             {selectedHolidayRow?.transaction_details?.length >0 ?
                                            // selectedHolidayRow?.transaction_details?.map((transaction ,index) => ( 
                                                <TableRow>
                                                    <TableCell>1</TableCell>
                                                    <TableCell>{selectedHolidayRow?.transaction_details?.map(item => item.invoice_number).join(', ')}</TableCell>
                                                    <TableCell>{selectedHolidayRow?.transaction_details?.map(item => item.transaction_reference_number).join(', ')}</TableCell>
                                                  
                                                    <TableCell>{selectedHolidayRow?.transaction_details?.map(item => moment(item.transaction_date).format('DD-MM-YYYY')).join(', ')}</TableCell>
                                                    <TableCell style={{textTransform:"capitalize"}}>{gatewaylist?.map(item => item).join(', ')}</TableCell>
                                                    <TableCell>{selectedHolidayRow?.payment_details[0]?.paid_amount.toFixed(2)}</TableCell>
                                                    <TableCell>{selectedHolidayRow?.payment_details[0].currency}</TableCell>
                                                    {/* <TableCell>{transaction?.payment_remarks}</TableCell> */}
                                                    {/* <TableCell style={{cursor: "pointer"}} onClick={()=>DownloadPdf(transaction?.receipt_pdf)}> <FontAwesomeIcon icon={faDownload}  /></TableCell> */}
                                                </TableRow>
                                            :
                                            <TableRow>
                                            <TableCell colSpan={9} style={{ textAlign: 'center' }}>No Transaction Information found</TableCell>
                                        </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                  <h4>Fare Details</h4>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>Golfer Price</th>
                                            <td>{selectedHolidayRow?.currency} {selectedHolidayRow?.total_price_of_golfers.toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <th>Non Golfer Price</th>
                                            <td>{selectedHolidayRow?.currency} {selectedHolidayRow?.total_price_of_non_golfers.toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <th>convenience Fee</th>
                                            <td>{selectedHolidayRow?.currency} {selectedHolidayRow?.convenience_fee > 0 ? selectedHolidayRow?.convenience_fee.toFixed(2) : 0.00}</td>
                                        </tr>
                                        <tr>
                                            <th>Tax</th>
                                            <td>{selectedHolidayRow?.currency} {selectedHolidayRow?.tax > 0 ? selectedHolidayRow?.tax.toFixed(2) : 0.00}</td>
                                        </tr>
                                        <tr>
                                            <th>Total</th>
                                            <td>{selectedHolidayRow?.currency} {selectedHolidayRow?.total_price.toFixed(2)}</td>
                                        </tr>
                                        {selectedHolidayRow?.transaction_details && selectedHolidayRow?.transaction_details?.length >0 && (
                                            <tr>
                                                <th>Paid Price</th>
                                                <td>{selectedHolidayRow?.currency} {selectedHolidayRow?.transaction_details.reduce((total, detail) => total + detail?.transaction_price, 0).toFixed(2)}</td>
                                            </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </Box>
            </div>
        </Modal>
        {/* HOLIDAY MODAL END */}
        {/* IP MODAL */}
        <Modal open={instantopen} onClose={handleCloseIP}>
            <div className='dashboard-popup-box'>
                <Box className="modal-box">
                    {selectedIpRow && (
                        <>
                            <h2 className="modal-header">Booking History</h2>
                            <button className="close-button" onClick={handleCloseIP}>
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                            <div className="modal-content">
                                <div className="modal-section">
                                    <p><strong>Name:</strong> {selectedIpRow?.customer_name || 'N/A'}</p>
                                    <p><strong>Nationality:</strong> {selectedIpRow?.customer_nationality || ''}</p>
                                </div>
                                <div className="modal-section">
                                    <p style={{'textTransform' : 'unset'}}><strong>Email:</strong> {selectedIpRow?.customer_email || 'N/A'}</p>
                                  
                                </div>
                                <div className="modal-section">
                                    <p><strong>Mobile No:</strong> {getFormattedPhone(selectedIpRow?.customer_phone) || 'N/A'}</p>
                                   
                                </div>
                                <div className="modal-section">
                                    <p><strong>Booking Ref No:</strong> {selectedIpRow?.booking_ref_number || ''}</p>
                                </div>
                               
                            </div>
                            <div className="modal-details">
                                <h3>Course Details</h3>
                                <TableContainer sx={{ maxHeight: 440 }} className='popup-dash-details'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: 'green' }} className='book-tr-th'>
                                                <TableCell sx={{ color: 'white' }}>Course ID</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Booking Confirmation No</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Booking Status</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Date</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Tee Time</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Course Name</TableCell>
                                                <TableCell sx={{ color: 'white' }}>No of Golfers</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Golf Price</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Total Price</TableCell>
                                                
                                              
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedIpRow?.selected_course_relation?.map((course) => (
                                                <TableRow>
                                                    <TableCell>{course?.id || 'N/A'}</TableCell>
                                                   
                                                    <TableCell>{course?.booking_confirmation_number}</TableCell>
                                                    <TableCell><span style={{ color: 'primary' }}>
                                                        {course.status === 1 ? (
                                                            <Badge className="badge-format unconformed" badgeContent="Unconfirmed" />
                                                        ) : course.status === 2 ? (
                                                            <Badge className="badge-format p-conformed" badgeContent="Partially Confirmed" />
                                                        ) : course.status === 3 ? (
                                                            <Badge className="badge-format conformed"  badgeContent="Confirmed" />
                                                        ) : (
                                                            <Badge className="badge-format cancelled"  badgeContent="Cancelled" />
                                                        )}
                                                    </span>
                                                    </TableCell>
                                                    <TableCell>{moment(course?.date).format("DD-MM-YYYY")}</TableCell>
                                                    <TableCell>{course?.tee_time}</TableCell>
                                                    <TableCell>{course?.golf_course_name}</TableCell>
                                                    <TableCell>{course?.no_of_golfers}</TableCell>
                                                    <TableCell>{selectedIpRow?.currency} {course?.price_of_golfers?.toFixed(2)}</TableCell>
                                                    <TableCell>{selectedIpRow?.currency} {(course?.price_of_golfers * course?.no_of_golfers).toFixed(2)}</TableCell>
                                                   
                                                   
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <h3>Hotel Details</h3>
                                <TableContainer sx={{ maxHeight: 440 }} className='popup-dash-details'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: 'green' }} className='book-tr-th'>
                                                <TableCell sx={{ color: 'white' }}>Course ID</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Booking Confirmation No</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Booking Status</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Check-In-Date</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Check-Out-Date</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Hotel Name</TableCell>
                                                <TableCell sx={{ color: 'white' }}>No of Adults</TableCell>
                                                <TableCell sx={{ color: 'white' }}>No of Child</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Room Price</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Total Price</TableCell>
                                                
                                              
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedIpRow?.selected_hotel_relation?.map((hotel) => (
                                                <TableRow>
                                                    <TableCell>{hotel?.id || 'N/A'}</TableCell>
                                                   
                                                    <TableCell>{hotel?.booking_confirmation_number}</TableCell>
                                                    <TableCell><span style={{ color: 'primary' }}>
                                                        {hotel.status === 1 ? (
                                                            <Badge className="badge-format unconformed" badgeContent="Unconfirmed" />
                                                        ) : hotel.status === 2 ? (
                                                            <Badge className="badge-format p-conformed" badgeContent="Partially Confirmed" />
                                                        ) : hotel.status === 3 ? (
                                                            <Badge className="badge-format conformed"  badgeContent="Confirmed" />
                                                        ) : (
                                                            <Badge className="badge-format cancelled"  badgeContent="Cancelled" />
                                                        )}
                                                    </span>
                                                    </TableCell>
                                                    <TableCell>{moment(hotel?.check_in_date).format("DD-MM-YYYY")}</TableCell>
                                                    <TableCell>{moment(hotel?.check_out_date).format("DD-MM-YYYY")}</TableCell>
                                                    <TableCell>{hotel?.hotel_name}</TableCell>
                                                    <TableCell>{hotel?.adults}</TableCell>
                                                    <TableCell>{hotel?.child}</TableCell>
                                                    <TableCell>{selectedIpRow?.currency} {hotel?.room_price?.toFixed(2)}</TableCell>
                                                    <TableCell>{selectedIpRow?.currency} {(hotel?.total_price).toFixed(2)}</TableCell>
                                                   
                                                   
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                
                                <h3>Invoice Details</h3>
                                <TableContainer sx={{ maxHeight: 440 }} className='popup-dash-details'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: 'green' }} className='book-tr-th'>
                                                <TableCell sx={{ color: 'white' }}>S.no</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Booking Reference No</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Invoice No</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Invoice Date</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Service Type</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Customer Name</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Email</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Invoice Price</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Total Price</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Invoice</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedIpRow?.payment_details?.length >0 ?selectedIpRow?.payment_details?.map((invoice ,index) => (
                                                <TableRow>
                                                    <TableCell>{index +1}</TableCell>
                                                    <TableCell>{invoice?.booking_reference_number}</TableCell>
                                                    <TableCell>{invoice?.invoice_number}</TableCell>
                                                    <TableCell>{moment(invoice?.invoice_date).format("DD-MM-YYYY")}</TableCell>
                                                    <TableCell>{invoice?.service_type}</TableCell>
                                                    <TableCell>{invoice?.customer_name}</TableCell>
                                                    <TableCell>{invoice?.customer_email}</TableCell>
                                                    <TableCell>{selectedIpRow?.currency} {invoice?.invoice_price?.toFixed(2)}</TableCell>
                                                    <TableCell>{selectedIpRow?.currency} {invoice?.total_price?.toFixed(2)}</TableCell>
                                                    <TableCell>
                                                    <FontAwesomeIcon style={{ cursor: "pointer" }} onClick={() => DownloadPdf(invoice?.invoice_pdf)} icon={faDownload} />
                                                    {' '}
                                                    {invoice?.payment_link && invoice?.payment_link !== '' && (
                                                        <a target='_blank' href={invoice?.payment_link}>Pay</a>
                                                    )}
                                                    </TableCell>
                                                </TableRow>
                                            )):
                                            <TableRow>
                                                <TableCell colSpan={9} style={{ textAlign: 'center' }}>No invoice Informations found</TableCell>
                                            </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <h3>Transaction Details</h3>
                                <TableContainer sx={{ maxHeight: 440 }} className='popup-dash-details'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: 'green' }} className='book-tr-th'>
                                                <TableCell sx={{ color: 'white' }}>S.no</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Invoice Number</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Transaction Ref ID</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Transaction Date	</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Payment Gateway</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Transaction Price</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Currency</TableCell>
                                                {/* <TableCell sx={{ color: 'white' }}>payment_remarks</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Action</TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedIpRow?.transaction_details?.length >0 ?
                                            // selectedRow?.transaction_details?.map((transaction ,index) => (
                                                <TableRow>
                                                   <TableCell>1</TableCell>
                                                    <TableCell>{selectedIpRow?.transaction_details?.map(item => item.invoice_number).join(', ')}</TableCell>
                                                    <TableCell>{selectedIpRow?.transaction_details?.map(item => item.transaction_reference_number).join(', ')}</TableCell>
                                                  
                                                    <TableCell>{selectedIpRow?.transaction_details?.map(item => moment(item.transaction_date).format('DD-MM-YYYY')).join(', ')}</TableCell>
                                                    <TableCell style={{textTransform:"capitalize"}}>{gatewaylist?.map(item => item).join(', ')}</TableCell>
                                                    <TableCell>{selectedIpRow?.payment_details[0]?.paid_amount.toFixed(2)}</TableCell>
                                                    <TableCell>{selectedIpRow?.payment_details[0].currency}</TableCell>
                                                    {/* <TableCell>{transaction?.payment_remarks}</TableCell> */}
                                                    {/* <TableCell style={{cursor: "pointer"}} onClick={()=>DownloadPdf(transaction?.receipt_pdf)}> <FontAwesomeIcon icon={faDownload}  /></TableCell> */}
                                                </TableRow>
                                            :
                                            <TableRow>
                                                <TableCell colSpan={9} style={{ textAlign: 'center' }}>No Transaction Information found</TableCell>
                                            </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div className='myacc-floatright'>
                                    <h4>Fare Details</h4>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th colSpan="4">Base Price</th>
                                                {/* <td>${selectedRow?.details?.reduce((sum, prod) => sum + prod.total_price, 0) || 'N/A'}</td> */}
                                                <td style={{"textAlign":"right"}}>{selectedIpRow?.currency} {selectedIpRow?.total_price.toFixed(2)}</td>
                                            </tr>
                                            {/* <tr>
                                                <th>Convenience Fee</th>
                                                <td>{CURRENCY_CODE} {calculateConvineanceFeeTotal()}</td>
                                            </tr> */}
                                            <tr>
                                                <th colSpan="4">Total</th>
                                                {/* <td>{CURRENCY_CODE} {(parseInt(calculateBasePrice()) + parseInt(calculateConvineanceFeeTotal()))?.toFixed(2)}</td> */}
                                                <td style={{"textAlign":"right"}}>{selectedIpRow?.currency} {selectedIpRow?.total_price.toFixed(2)}</td>
                                            </tr>
                                            {selectedIpRow?.transaction_details && selectedIpRow?.transaction_details?.length >0 && (
                                            <tr>
                                                <th colSpan="4">Paid Price</th>
                                                <td style={{"textAlign":"right"}}>{selectedIpRow?.currency} {selectedIpRow?.transaction_details.reduce((total, detail) => total + detail?.transaction_price, 0).toFixed(2)}</td>
                                            </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    )}
                </Box>
            </div>
        </Modal>
        {/* IP MODAL END */}
    </div>
    )
}
export default MyBookingLists;