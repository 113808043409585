import { Divider, Grid, Typography } from '@mui/material'
import { has } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { redirect, useNavigate } from 'react-router-dom'
import { BOOKING_INPUT, BOOKING_TYPE, SELECTED_GOLF_HOLIDAY, SET_SELECTED_BOOKING } from '../../Redux/constants/bookingConstants'
import Noimage from "../../assets/images/no-img.png";
import Slider from 'react-slick';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp,faArrowRight,faArrowLeft} from "@fortawesome/free-solid-svg-icons";
const ExperianceList = ({events}) => {

  const {userCurrencyCode , currencyRate} = useSelector(state => state.currency);
  const [currencyCode , setCurrencyCode] = useState('')
  const [currencyRateList , setCurrencyRateList] = useState([]);

  useEffect(() => {
    if(userCurrencyCode){
        setCurrencyCode(userCurrencyCode)
}
  }, [userCurrencyCode]);

  useEffect(() => {
    if(currencyRate){
      setCurrencyRateList(currencyRate)
}
  }, [currencyRate]);
  
  const fnctionConvertingPriceWithCurrency = (price , currency) =>{  
    const currencyRate = currencyRateList[currency];
    const convertedPrice =  price / currencyRate
    return convertedPrice.toFixed(2);
}

  const functionPriceStartFrom = (priceArray) => {
    if (priceArray?.length > 0) {
      const prices = priceArray.map(item => item.up_golfer_single);
      const lowestPrice = Math.min(...prices);
      const currencyPerPrice = currencyRateList[priceArray[0]?.currency_id?.currencyCode];
      const convertedPrice =  lowestPrice / currencyPerPrice
      return convertedPrice.toFixed(2);
    }
    return 0.00;
  };


  
  
    const navigation = useNavigate();
    
    const dispatch = useDispatch()
    const { bookingData } = useSelector(state => state.booking)
    const SearchDetailsPage = (slug) => {   
        navigation('/SearchDetailsPage/'+slug)
    };
    const [priceStartFrom , setPriceStartFrom] = useState(0)
   
    const Ratings = (value) => {
        let stars = [];
            for (let i = 1; i <= value; i++) {
            stars.push(
            <p><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
            viewBox="0 0 20 20" fill="none">
            <path
            d="M11.4421 2.92495L12.9087 5.85828C13.1087 6.26662 13.6421 6.65828 14.0921 6.73328L16.7504 7.17495C18.4504 7.45828 18.8504 8.69162 17.6254 9.90828L15.5587 11.975C15.2087 12.325 15.0171 13 15.1254 13.4833L15.7171 16.0416C16.1837 18.0666 15.1087 18.85 13.3171 17.7916L10.8254 16.3166C10.3754 16.05 9.63375 16.05 9.17541 16.3166L6.68375 17.7916C4.90041 18.85 3.81708 18.0583 4.28375 16.0416L4.87541 13.4833C4.98375 13 4.79208 12.325 4.44208 11.975L2.37541 9.90828C1.15875 8.69162 1.55041 7.45828 3.25041 7.17495L5.90875 6.73328C6.35041 6.65828 6.88375 6.26662 7.08375 5.85828L8.55041 2.92495C9.35041 1.33328 10.6504 1.33328 11.4421 2.92495Z"
            fill="#FFB800" stroke="#FFB800" stroke-width="1.5"
            stroke-linecap="round" strokeLinejoin="round" />
            </svg></p>
        );
    }
        return stars;
    }

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: <FontAwesomeIcon icon={faArrowRight} size="xs" />,
      prevArrow: <FontAwesomeIcon icon={faArrowLeft} size="xs" />,
    };

    const selectCurrentHoliday = async () => {

        dispatch({
            type: BOOKING_TYPE,
            payload: 'PAL'
        })

        dispatch({
            type: SELECTED_GOLF_HOLIDAY,
            payload: events
        })

        dispatch({
            type: SET_SELECTED_BOOKING,
            payload: {
                booking: bookingData,
                selectedGolfHoliday: events,
                id: 'current'
            }
        })

        navigation("/ExperiencesDetailsPage")
    }

    const totalNights = events.golf_holidays.number_of_nights
    .map(Number) 
    .reduce((acc, nights) => acc + nights, 0);

    const totalRounds = events.golf_holidays.number_of_golf_rounds
    .map(Number) 
    .reduce((acc, rounds) => acc + rounds, 0); 



    const [isExpanded, setIsExpanded] = useState(false);

  

    return (
      <Grid item xs={12} lg={3.8} md={5.8} key={events?.golf_holidays?.id} className="package-card">
      <div className='final-card-imgfi'>
      <div className='card-image'>
        <Slider {...settings}>
         
          {
            events?.image_galleries?.map(image =>(
              <div className="card-images">
              <img src={image.image} alt={events.holiday_name} onClick={selectCurrentHoliday} />
            </div>
            ))
          }
        </Slider>
       </div>
       <div className="slider-tags-final">
<span className="featured-final">{events?.golf_holidays?.price_type}</span>
{/* <span className="top-pick-final">Top Pick</span>
<span className="event-final">Event</span> */}
</div>
      </div>
      <div className="card-content">
        <div className='card-confirst'>
          <h3>   
          <div dangerouslySetInnerHTML={{ __html: events?.golf_holidays?.holiday_name }} onClick={selectCurrentHoliday}/>
          </h3>
          {/* <p className="nrating">★ {holidays?.golf_holidays?.rating}</p> */}
        </div>
        <div className="location-and-rating">
          <p className="days"><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"><path d="M12.0001 4.65002C7.22008 4.65002 3.33008 8.54002 3.33008 13.32C3.33008 18.1 7.22008 22 12.0001 22C16.7801 22 20.6701 18.11 20.6701 13.33C20.6701 8.55002 16.7801 4.65002 12.0001 4.65002ZM12.7501 13C12.7501 13.41 12.4101 13.75 12.0001 13.75C11.5901 13.75 11.2501 13.41 11.2501 13V8.00002C11.2501 7.59002 11.5901 7.25002 12.0001 7.25002C12.4101 7.25002 12.7501 7.59002 12.7501 8.00002V13Z" fill="#292D32"></path><path d="M14.8906 3.45H9.11062C8.71062 3.45 8.39062 3.13 8.39062 2.73C8.39062 2.33 8.71062 2 9.11062 2H14.8906C15.2906 2 15.6106 2.32 15.6106 2.72C15.6106 3.12 15.2906 3.45 14.8906 3.45Z" fill="#292D32"></path></svg></span> {events?.golf_holidays?.number_of_days?.length} days | {events?.golf_holidays?.number_of_golf_rounds?.length} Rounds</p>
          <h3 className="location"><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
            <path d="M7.62906 3.56969C7.80845 3.47184 7.99906 3.62237 7.99906 3.8267V17.3825C7.99906 17.6058 7.84665 17.7946 7.64926 17.8988C7.64249 17.9024 7.63576 17.906 7.62906 17.9097L5.27906 19.2497C3.63906 20.1897 2.28906 19.4097 2.28906 17.5097V7.77969C2.28906 7.14969 2.73906 6.36969 3.29906 6.04969L7.62906 3.56969Z" fill="#00ACBF" />
            <path d="M14.7219 6.10241C14.8922 6.18676 15 6.3604 15 6.55047V19.7036C15 20.0721 14.615 20.314 14.283 20.1541L10.033 18.1065C9.85998 18.0231 9.75 17.8481 9.75 17.656V4.44571C9.75 4.07486 10.1396 3.83306 10.4719 3.99765L14.7219 6.10241Z" fill="#00ACBF" />
            <path d="M22 6.49006V16.2201C22 16.8501 21.55 17.6301 20.99 17.9501L17.4986 19.951C17.1653 20.1421 16.75 19.9014 16.75 19.5172V6.33038C16.75 6.15087 16.8462 5.98513 17.0021 5.89615L19.01 4.75006C20.65 3.81006 22 4.59006 22 6.49006Z" fill="#00ACBF" />
          </svg></span>{events?.golf_holidays?.country_id[0].name}</h3>
        </div>
        <div className='pkg-describ'>
          <p>
          <div dangerouslySetInnerHTML={{ __html: events?.golf_holidays?.description }} />
          </p>
        </div>
        <div className="card-footer">
          <div className='card-foot-para'>
            <p className="price">{currencyCode} {functionPriceStartFrom(events?.holiday_price)}</p>
            <p className='coming-de'>Onwards</p>
          </div>
          <button className="gradient-btn" name="Book Now" onClick={selectCurrentHoliday}>Book Now <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></button>
        </div>
      </div>
    </Grid>
    )
}

export default ExperianceList