import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import { useSelector, useDispatch, } from 'react-redux'
import { redirect, useNavigate } from 'react-router-dom'
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Payment.scss';
import moment from "moment";
import razorpay from '../../assets/images/razorpay.png';
import flywire from '../../assets/images/flywire.png';
import Stripe from '../../assets/images/Stripe-Logo-blue.png';
import Manual from '../../assets/images/manual.png';
import onlineimgfi from '../../assets/images/onlineimgfi.png';
import payNow from '../../assets/images/paynow.jpg';
import paynowLogo from '../../assets/images/paynowLogo.jpg';
import onlineimgfi1 from '../../assets/images/Payment by tapping card over payment terminal.png';
import axios from '../../CustomAxios'
import { API_URL } from '../../config/Constants';
import { BASE_URL } from '../../config/Constants';
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom';
import Course from './Course';
import Instant from './Instant';
import Holiday from './Holiday';
// import Flywire from '@flywire/flywire-js';
// import "./flywire_parameters.js"; 
// import "./checkout.js"; 
import useRazorpay from "react-razorpay";
const PaymentPageDetails = ({ pageContents, loading }) => {
  const location = useLocation();
  const [Razorpay] = useRazorpay();
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const { user, isAuthenticated } = useSelector(state => state.auth);
  const [bookingData, setBookingData] = useState();
  const [invoiceData, setInvoiceData] = useState();
  const [BookingPrice, setBookingPrice] = useState(0);
  const [type, setType] = useState("");
  const [bank_name, setBankName] = useState("");
  const [paymentmode, setPaymentMode] = useState("");
  const [credit_amount, setAmount] = useState(0);
  const [description, setDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [notinvoiceGenerated, setNotInvoiceGenerated] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const navigation = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const confirmManualPayment = async(event)=>{
    event.preventDefault()
   
    const formData = new FormData();
    formData.append("bank_name", bank_name);
    formData.append("bank_type", paymentmode);
    formData.append("image", selectedImage);
    formData.append("invoice_number", invoiceData?.invoice_number);
    formData.append("invoice_customername", bookingData?.customer_name);
    formData.append("invoice_customeremail", bookingData?.customer_email);
    formData.append("invoice_contactno",  bookingData?.customer_phone);
    formData.append("payment_gateway_id", paymentmode);
    formData.append("user_id", bookingData?.payment_details[0]?.customer_id);
    formData.append("payment_currency", invoiceData?.currency);
    formData.append("booking_reference_number", bookingData?.booking_ref_number);
    formData.append("payment_remarks", description);
    formData.append("payment_response_json", '');
    formData.append("transaction_date", moment(startDate).format("YYYY-MM-DD hh:mm"));
    formData.append("Payment_due_amount", invoiceData?.invoice_price);
    formData.append("convenience_fee",0);
    formData.append("transaction_price",credit_amount);
    formData.append("total_price",BookingPrice);
    formData.append("transaction_reference_number","manual");
   

    try {
      const response = await axios({
        method: "post",
        url: API_URL + '/manual-payment',
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then(async ( responce) => {
        navigation('/GolfPaymentSuccess')
      })
    } catch(error) {
      console.log(error)
    }
    console.log(JSON.stringify(formData))
  }

  const confirmPayNowPayment = async(event)=>{
    event.preventDefault()
   
    const formData = new FormData();
    formData.append("bank_name", payNowBankName);
    formData.append("bank_type", payNowPaymentMode);
    formData.append("image", PayNowselectedImage);
    formData.append("invoice_number", invoiceData?.invoice_number);
    formData.append("invoice_customername", bookingData?.customer_name);
    formData.append("invoice_customeremail", bookingData?.customer_email);
    formData.append("invoice_contactno",  bookingData?.customer_phone);
    formData.append("payment_gateway_id", payNowPaymentMode);
    formData.append("user_id", bookingData?.payment_details[0]?.customer_id);
    formData.append("payment_currency", invoiceData?.currency);
    formData.append("booking_reference_number", bookingData?.booking_ref_number);
    formData.append("payment_remarks", PayNowdescription);
    formData.append("payment_response_json", '');
    formData.append("transaction_date", moment(payNowstartDate).format("YYYY-MM-DD hh:mm"));
    formData.append("Payment_due_amount", invoiceData?.invoice_price);
    formData.append("convenience_fee",0);
    formData.append("transaction_price",payNowAmount);
    formData.append("total_price",invoiceData?.invoice_price);
    formData.append("transaction_reference_number","PayNow");
   

    try {
      const response = await axios({
        method: "post",
        url: API_URL + '/manual-payment',
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then(async ( responce) => {
        navigation('/GolfPaymentSuccess')
      })
    } catch(error) {
      console.log(error)
    }
    console.log(JSON.stringify(formData))
  }
  const bankList=["Canara Bank","State Bank","Axis Bank","Hdfc Bank"];
  const paymentMode=["Bank Transfer SGD account","Bank Transfer INR account","Bank Transfer USG account"];
  
  const handleFileSelect = (event) => {
    setSelectedImage(event.target.files[0])
  }

  const handlePayNowFileSelect = (event) => {
    setPayNowSelectedImage(event.target.files[0])
  }
  const handlePayment = async (params) => {
    const order ={id:1} //await createOrder(params); //  Create order on your backend
   
    const options = {
      key: "rzp_test_OmS0gbkvdImHcl", // Enter the Key ID generated from the Dashboard
      amount: BookingPrice * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: invoiceData?.currency,
      name: "Golftripz",
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      // order_id: "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler: function (response) {
        console.log(response)
        var payloadData = {
          invoice_id: invoiceData?.invoice_number,
          invoice_customername : bookingData?.customer_name,
          invoice_customeremail : bookingData?.customer_email,
          invoice_contactno : bookingData?.customer_phone,
          Payment_due_amount : invoiceData?.invoice_price / 100,
          invoice_date: invoiceData?.invoice_date,
          invoice_price: invoiceData?.invoice_price ,
          paid_amount: BookingPrice ,
          booking_reference_number: bookingData?.booking_ref_number,
          invoice_number: invoiceData?.invoice_number,
          total_price: BookingPrice ,
          transaction_price: BookingPrice ,
          payment_gateway_id: "4--RazorPay",  
          payment_mode: "online",
          payment_response_json: response,
          user_id: bookingData?.payment_details[0]?.customer_id,
          payment_currency: invoiceData?.currency,
          payment_remarks: "",
          transaction_reference_number: response?.razorpay_payment_id,
        }
        console.log(payloadData)
        axios(API_URL + '/add-payment-details', {
          method: 'POST',
          data: payloadData,
          headers: {
              'Content-Type': 'application/json'
          }
        })  .then(async ( responce) => {
            console.log(responce)
            if(responce){
              navigation('/GolfPaymentSuccess')
            }
           
        });
      },
      prefill: {
        name: bookingData?.customer_name,
        email: bookingData?.customer_email,
        contact: "6374707609",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    }; 
    
    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
  
    rzp1.open();
  };
  const pay=async()=> {
    // alert(invoiceData?.currency);
    var PriceBooking=BookingPrice;
    if(invoiceData?.currency != "SGD"){
      const Currency=invoiceData?.currency;
      const currency_app_id = process.env.REACT_APP_CURRENCY_APP_ID;
      const exchangeRatesResponse = await fetch(`https://openexchangerates.org/api/latest.json?app_id=${currency_app_id}&base=${Currency}`);
      if (!exchangeRatesResponse.ok) {
          throw new Error('Failed to fetch exchange rates');
      }
      const exchangeRatesData = await exchangeRatesResponse.json();
      // alert(JSON.stringify(exchangeRatesData?.rates))
      const currencyRate = exchangeRatesData?.rates["SGD"];
      // alert(currencyRate)
        const convertedPrice =  BookingPrice * currencyRate
        //  alert(convertedPrice.toFixed(2));
         PriceBooking =convertedPrice.toFixed(2)
    }
    // return
    // alert(PriceBooking)
    var config = {
        env: "demo",
        recipientCode: "GTP",
        amount: PriceBooking ,

        onInvalidInput: function(errors) {
            errors.forEach(function(error) {
                alert(error.msg);
            });
        },

        requestPayerInfo: true,
        requestRecipientInfo: true,
        skipCompletedSteps: true,
        payerEmailNotifications: false,
        skipMQC: true,

        recipientFields: {
            booking_reference: "REF1234",
            client_id: "CLI1234",
        },
  
        onCompleteCallback: function(args) {
            
             console.log(args);
            var result=JSON.stringify(args);
           
             var payloadData = {
      invoice_id: invoiceData?.invoice_number,
      invoice_customername : bookingData?.customer_name,
      invoice_customeremail : bookingData?.customer_email,
      invoice_contactno : bookingData?.customer_phone,
      Payment_due_amount : invoiceData?.invoice_price / 100,
      invoice_date: invoiceData?.invoice_date,
      invoice_price: invoiceData?.invoice_price ,
      paid_amount: BookingPrice ,
      booking_reference_number: bookingData?.booking_ref_number,
      invoice_number: invoiceData?.invoice_number,
      total_price: BookingPrice ,
      transaction_price: BookingPrice ,
      payment_gateway_id: "4--flywire",
      payment_mode: "online",
      payment_response_json: result,
      user_id: bookingData?.payment_details[0]?.customer_id,
      payment_currency: invoiceData?.currency,
      payment_remarks: "",
      transaction_reference_number: args?.reference,
    }
    console.log(payloadData)
    axios(API_URL + '/add-payment-details', {
      method: 'POST',
      data: payloadData,
      headers: {
          'Content-Type': 'application/json'
      }
    })  .then(async ( responce) => {
       
        if(responce){
          navigation('/GolfPaymentSuccess')
        }
       
    });
           
        },
        // Show the cheapest local payment option first but prefer bank transfers
        // over cards and online methods if they are the same price
        paymentOptionsConfig: {
            filters: [{
                    currency: ["local","foreign"]
                    // currency:[invoiceData?.currency]
                    // currency: ["INR"]
                },
                {
                    amount: "asc"
                },
                {
                    type:  ["bank_transfer", "credit_card", "online"]
                },
            ],
        },
        nonce: "REF1234",
        returnUrl: "https://api.response.php/flywire-notifications",
        callbackUrl: "https://api.response.php/flywire-notifications",
        callbackId: "REF1234",
        callbackVersion: "2"
        
    };
    

    var modal = window.FlywirePayment.initiate(config);
    modal.render();
}

  const handleFlywire=()=>{
   
    var config = {
      // See sample configuration details in section below
      // Set your environment (demo, production)
      env: "demo",
  
      // Set your unique code (may differ between environments)
      recipient: "YVP",
  
      // Set your preferred locale
      locale: "en",
      // Set provider to checkout_edu
      provider: "checkout_edu",
  
      // Pre-populate fields with data (based on your portal settings)
      invoice_customername : bookingData?.customer_name,
      invoice_customeremail : bookingData?.customer_email,
      invoice_contactno : bookingData?.customer_phone,
      amount: BookingPrice * 100,
      sender_country: 'US',
      sender_first_name:bookingData?.customer_name,
      sender_last_name: bookingData?.customer_name,
      // sender_address1: "ooty",
      // sender_address2: "Bugis Junction",
      // // sender_state: "Tamilnadu",
      // sender_zip_code: "123456",
      sender_city: "Ooty",
      sender_phone:bookingData?.customer_phone ,
      sender_email:bookingData?.customer_email,
      student_email:bookingData?.customer_email,
      student_first_name: bookingData?.customer_name,
      student_last_name:bookingData?.customer_name,
  
      // Enable payment status notification callbacks
      callback_id: "1234",
      callback_url: "https://hook.integromat.com/3nofk17o6bhbbocph5aux1uhsqbx6cf9",
  
      // Set the return url to redirect the user on process completion
      return_url:BASE_URL+ "GolfPaymentSuccess",
      
    };
  
    // Render the payment form when clicking on the button element
    var result=window.flywire.Checkout.render(config, "#flywire-pay");
    console.log("result",result)
    // var payloadData = {
    //   invoice_id: invoiceData?.invoice_number,
    //   invoice_id: invoiceData?.invoice_number,
    //   invoice_customername : bookingData?.customer_name,
    //   invoice_customeremail : bookingData?.customer_email,
    //   invoice_contactno : bookingData?.customer_phone,
    //   Payment_due_amount : invoiceData?.invoice_price / 100,
    //   invoice_date: invoiceData?.invoice_date,
    //   invoice_price: invoiceData?.invoice_price ,
    //   paid_amount: BookingPrice ,
    //   booking_reference_number: bookingData?.booking_ref_number,
    //   invoice_number: invoiceData?.invoice_number,
    //   total_price: BookingPrice ,
    //   transaction_price: BookingPrice ,
    //   payment_gateway_id: "4--flywire",
    //   payment_mode: "online",
    //   payment_response_json: [],
    //   user_id: bookingData?.payment_details[0]?.customer_id,
    //   payment_currency: invoiceData?.currency,
    //   payment_remarks: "",
    //   transaction_reference_number: '',
    // }
    // console.log(payloadData)
    // axios(API_URL + '/add-payment-details', {
    //   method: 'POST',
    //   data: payloadData,
    //   headers: {
    //       'Content-Type': 'application/json'
    //   }
    // })  .then(async ( responce) => {
    //     console.log(responce)
    //     if(responce){
    //       navigation('/GolfPaymentSuccess')
    //     }
       
    // });
  }

  const decrypt = (salt, encoded) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join("");
  };
  useEffect(() => {
    var data = location.search;
    // bookingref=GTGH-00029&price=1818364.83&invnumb=GTINV-00057
    if (data !== "") {
      var encryptedid = data.substr(1)
      var decrypted_string = decrypt("salt", encryptedid); // -> Hello
      console.log('decrypted_string', decrypted_string);
      // alert(decrypted_string)
      var bookingdata = decrypted_string.split('&');
      var invoicenumber=bookingdata[2].split('=')
      var bookingid = bookingdata[0].split('=');
      getBookingDetails(bookingid[1],invoicenumber[1])
      
    }

  }, [])
 
  const [selectedPayment, setSelectedPayment] = useState('');

  const handlePaymentChange = async(event) => {
   
    setSelectedPayment(event.target.value);
  };
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [manualPopupVisible, setManualPopupVisible] = useState(false);
  const [payNowPopupVisible, setPayNowPopupVisible] = useState(false);
  const popupRef = useRef(null);


  const [payNowBankName, setPayNowBankName] = useState('');
  const [payNowPaymentMode, setPayNowPaymentMode] = useState('');
  const [payNowAmount, setPayNowAmount] = useState(0);
  const [payNowstartDate, setPayNowStartDate] = useState(new Date());
  const [PayNowdescription, setPayNowDescription] = useState("");
  const [PayNowselectedImage, setPayNowSelectedImage] = useState(null);

  const handleButtonClick = () => {
    setPopupVisible(true);
  };

  const handleManualModalClick = () => {
    setManualPopupVisible(true);
  };
  const handlePayNow = () => {
    setPayNowPopupVisible(true);
  };


  const handleClosePopup = () => {
    setPopupVisible(false);
    setPaymentLoading(false)
  };
 
  const handleCloseManual = () => {
    setManualPopupVisible(false);
    setPaymentLoading(false)
  };
  const handleClosePayNow = () => {
    setPayNowPopupVisible(false);
    setPaymentLoading(false)
  };

  const handleClickOutside = (event) => {
    // Prevent closing popup if clicking outside
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      return;
    }
  };
  const handleManualClickOutside = (event) => {
    // Prevent closing popup if clicking outside
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      return;
    }
  };
  const handlePayNowClickOutside = (event) => {
    // Prevent closing popup if clicking outside
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      return;
    }
  };
  const stripe = useStripe();
  const elements = useElements();

  const confirmPayment = async () => {
    console.log("datas",bookingData)
    let data = {
      amount: parseFloat(BookingPrice),
      currency: invoiceData?.currency,
      email: user?.email
    }
    
    setPaymentLoading(true)
    axios(API_URL + '/payment', {
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(async ({ data }) => {
      let customerId = data?.data?.customer
      const { paymentIntent, error } = await stripe.confirmCardPayment(data.data?.client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: user?.name
          },
        },
        setup_future_usage: 'off_session'
      });

      if (error) {
        setPaymentLoading(false);
        toast.error(error.message);
      } else {
        if (paymentIntent.status === "succeeded") {
          // SEND THE API REQUET WITH DETAILS
         
          setPopupVisible(false);
          var payloadData = {
            invoice_id: invoiceData?.invoice_number,
            invoice_customername : bookingData?.customer_name,
            invoice_customeremail : bookingData?.customer_email,
            invoice_contactno : bookingData?.customer_phone,
            Payment_due_amount : invoiceData?.invoice_price / 100,
            invoice_date: invoiceData?.invoice_date,
            invoice_price: invoiceData?.invoice_price / 100,
            paid_amount: paymentIntent?.amount / 100,
            Payment_due_amount:invoiceData?.invoice_price / 100,
            booking_reference_number: bookingData?.booking_ref_number,
            invoice_number: invoiceData?.invoice_number,
            total_price: paymentIntent?.amount / 100,
            transaction_price: paymentIntent?.amount / 100,
            payment_gateway_id: "2--strip",
            payment_mode: "online",
            payment_response_json: paymentIntent,
            user_id: bookingData?.payment_details[0]?.customer_id,
            payment_currency: paymentIntent?.currency,
            payment_remarks: "",
            transaction_reference_number: paymentIntent?.id,
          }
          console.log(payloadData)
          axios(API_URL + '/add-payment-details', {
            method: 'POST',
            data: payloadData,
            headers: {
                'Content-Type': 'application/json'
            }
          })  .then(async ( responce) => {
              // console.log(responce)
              if(responce?.status){
                navigation('/GolfPaymentSuccess')
              }
             
          });
        }
      }

    }).catch(err => {
      setPaymentLoading(false);
    })

  }
  const [gateWayDatas, setGateWayList] = useState([]);
  const [gateWayMode, setGateWaymode] = useState([]);
  const [coursesList, setCourses] = useState([]);
  const getBookingDetails = async (id,invoice_number) => {

    await axios(API_URL + '/get-reference-booking-details/' + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(function (response) {

      const { data } = response.data;
      if (data) {
        console.log("dataaaa", data)
        if (data?.instant_package) {
          setType("instant")
          var invoiceData = {
            invoice_id: bookingData?.instant_package?.payment_details?.invoice_number,
            invoice_date: bookingData?.instant_package?.payment_details?.invoice_date,
            invoice_price: bookingData?.instant_package?.payment_details?.invoice_price,
            currency: bookingData?.instant_package?.payment_details?.currency
          }
        } else if (data?.golf_course) {
          setType("course")
          var invoiceData = {
            invoice_id: bookingData?.golf_course?.payment_details?.invoice_number,
            invoice_date: bookingData?.golf_course?.payment_details?.invoice_date,
            invoice_price: bookingData?.golf_course?.payment_details?.invoice_price,
            currency: bookingData?.golf_course?.payment_details?.currency
          }
          setCourses(data?.golf_course?.booking_details?.filter(item =>
            item.status !== 5
                    ))
        } else if (data?.golf_holiday) {
          setType("holiday")
          var invoiceData = {
            invoice_id: bookingData?.golf_holiday?.payment_details?.invoice_number,
            invoice_date: bookingData?.golf_holiday?.payment_details?.invoice_date,
            invoice_price: bookingData?.golf_holiday?.payment_details?.invoice_price,
            currency: bookingData?.golf_holiday?.payment_details?.currency
          }
        }
         // alert(data?.payment_details.length)
         if(data?.payment_details?.length <= 0){
          // alert("Cannot make payment until invoice generated");
          setNotInvoiceGenerated(true)
        }
        // alert(invoice_number?.toString())
        var invoiceDetails=data?.payment_details?.filter(invoice=>invoice?.invoice_number === invoice_number)
       console.log("invoiceDetails",invoiceDetails)
        invoiceDetails=Object.assign({}, invoiceDetails[0]);
        var payment_gateways=invoiceDetails?.selected_payments;
        var gatewayParse=JSON.parse(payment_gateways)
        var Gateways=[];
        var Modes=[];
        gatewayParse?.map((gateway,mainindex)=>{
          gateway?.map((value,index)=>{
            if(value==="Yes"){
              var Dataobj={
                id:gateway[0],
                value:gateway[1],
                gateway_name:gateway[2],
              }
              // Dataobj = JSON.parse(Dataobj);
              Gateways.push(Dataobj)
            }else if(value ==="No"){
              var Payment_mode={
                id:gateway[0]+'--'+gateway[2],
                value:gateway[1],
                payment_mode:gateway[2],
              }
              Modes.push(Payment_mode)
            }
          })
        })
       
        console.log("mode",Modes)
        console.log("payment_gateways",Modes[1]?.id)
        setPaymentMode(Modes[0]?.id)
        setBankName(bankList[0])
        setGateWayList(Gateways)
        setSelectedPayment(Gateways[0]?.gateway_name)
        setGateWaymode(Modes)
        setInvoiceData(invoiceDetails)
        setBookingData(data)
        setBookingPrice(invoiceDetails?.invoice_price)
        setAmount(invoiceDetails?.invoice_price)
        setPayNowAmount(invoiceDetails?.invoice_price)
      }

    })
    
  }
  useEffect(() => {
        var Modes2=[];
        gateWayDatas?.map((each,index)=>{
      if(each?.value==="Yes" && (each?.gateway_name == "PayNow" || each?.gateway_name == "Paynow")){
        var Payment_mode={
          id:each?.id+'--'+each?.gateway_name,
        }
        Modes2.push(Payment_mode)
        setPayNowPaymentMode(Modes2[0]?.id)
      }
  })
 
  console.log("mode",Modes2)
  }, [gateWayDatas]);
console.log("gateWayMode",gateWayDatas)
console.log("invoiceData",invoiceData)
return (
    <div className='listitem'>
      <div className='paymentpage-container'>
        <h2 className='payment-pgnew-title'>Payment Details</h2>
        <Grid container spacing={2} className='paymentpage-drow'>
          <Grid xs={6} className='max-width-full'>
            <h2 className='payment-tripsum-tit'>Summary</h2>

            {type === 'course' ? (
              <Course datas={bookingData} />
            ) : type === 'instant' ? (
              <Instant datas={bookingData} />
            ) : (
              <Holiday datas={bookingData} />
            )}

          </Grid>
          <Grid xs={5} className='payment-tripsummary-container max-width-full'>
            <div className='payment-tripsum-rgt'>
              <div className='pay-summary-fulltotal'>
                <h5 className='payment-tripsum-tit'>Payment Summary</h5>
                <div>
                      <p>Booking Ref Number</p>
                      <h5>{bookingData?.booking_ref_number}</h5>
                </div>
                <div>
                      <p>Invoice Number</p>
                      <h5>{notinvoiceGenerated ?"Invoice not Generated for this booking": invoiceData?.invoice_number}</h5>
                </div>
                
                {type === 'course' ? (
                  <>
                    {coursesList?.map((coures) => (
                      <>
                        <div>
                          <p>{coures?.golf_course_name}</p>
                          <h5>{invoiceData?.currency}  {coures?.total_price?.toFixed(2)}</h5>
                        </div>
                      </>
                    ))
                    }
                    <div className='pay-trip-total'>
                      <p>Invoice Price</p>
                      <h5 className='pay-price'>{invoiceData?.currency} {payNowAmount?.toFixed(2)}</h5>
                </div>  

                    {/* <div className='pay-trip-total'>
                      <p>Total Price</p>
                      <h5 className='pay-price'>{invoiceData?.currency} {bookingData?.total_price?.toFixed(2)}</h5>
                    </div> */}
                  </>

                ) : type === 'instant' ? (
                  <>
                    {/* <div>
                      <p>Stay at Melbourne 5 Star Hotel</p>
                      <h5>$120</h5>
                    </div>
                    <div>
                      <p>Platform Fee</p>
                      <h5 className='pay-price'>$120</h5>
                    </div>
                    <div className='pay-trip-dis'>
                      <p>Discount</p>
                      <h5 className='pay-price'>$120</h5>
                    </div> */}
                    <div className='pay-trip-total'>
                      <p>Invoice Price</p>
                      <h5 className='pay-price'>{invoiceData?.currency} {bookingData?.payment_details[0]?.invoice_price?.toFixed(2)}</h5>
                    </div> 
                    {/* <div className='pay-trip-total'>
                      <p>Total Price</p>
                      <h5 className='pay-price'>{invoiceData?.currency} {bookingData?.total_price?.toFixed(2)}</h5>
                    </div> */}
                  </>
                ) : type === 'holiday' ?(
                  <>
                    {/* <div>
                      <p>Stay at Melbourne 5 Star Hotel</p>
                      <h5>$120</h5>
                    </div>
                    <div>
                      <p>Platform Fee</p>
                      <h5 className='pay-price'>$120</h5>
                    </div>
                    <div className='pay-trip-dis'>
                      <p>Discount</p>
                      <h5 className='pay-price'>$120</h5>
                    </div> */}
                    <div className='pay-trip-total'>
                      <p>Invoice Price</p>
                      <h5 className='pay-price'>{invoiceData?.currency} {BookingPrice?.toFixed(2)}</h5>
                    </div>
                    {/* <div className='pay-trip-total'>
                      <p>Total Price</p>
                      <h5 className='pay-price'>{invoiceData?.currency} {bookingData?.total_price?.toFixed(2)}</h5>
                    </div> */}
                  </>
                ):''}

              </div>
              <div>
  <h5 className='payment-tripsum-tit'>Choose Payment Type</h5>
  <div className="payment-selection">
    {gateWayDatas.map((gateways, index) => (
      <div 
        key={index} 
        className={`payment-option ${selectedPayment === gateways?.gateway_name ? 'selected' : ''}`} 
        onClick={() => handlePaymentChange({ target: { value: gateways?.gateway_name } })}
      >
        <input
          type="radio"
          name="payment"
          value={gateways?.gateway_name}
          id={gateways?.gateway_name}
          checked={selectedPayment === gateways?.gateway_name}
          onChange={(event) => handlePaymentChange(event)} // Keep for accessibility
        />
        <label htmlFor={gateways?.gateway_name} className="payment-label">
          <div className="payment-logo">
            {gateways?.gateway_name === "Stripe" ? (
              <img src={Stripe} alt="Stripe" />
            ) : gateways?.gateway_name === "RazorPay" ? (
              <img src={razorpay} alt="RazorPay" />
            ) : gateways?.gateway_name === "Flywire" ? (
              <img src={flywire} alt="Flywire" />
            )  : gateways?.gateway_name === "PayNow" ? (
              <img src={paynowLogo} alt="PayNow" />
            )  : gateways?.gateway_name === "Paynow" ? (
              <img src={paynowLogo} alt="PayNow" />
            ) : ''}
          </div>
        </label>
      </div>
    ))}
   {gateWayMode?.length >0 ?
    <div 
      className={`payment-option ${selectedPayment === 'manual' ? 'selected' : ''}`} 
      onClick={() => handlePaymentChange({ target: { value: 'manual' } })}
    >
      <input
        type="radio"
        name="payment"
        value="manual"
        id="manual"
        checked={selectedPayment === 'manual'}
        onChange={handlePaymentChange} // Keep for accessibility
      />
      <label htmlFor="manual" className="payment-label">
        <div className="payment-logo">
          <img src={onlineimgfi} alt="manual" />
        </div>
      </label>
    </div>:''}
  </div>
</div>

              {isPopupVisible && (
                <div
                  style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                  }}
                  onClick={handleClickOutside}
                >
                  <div
                    ref={popupRef}
                    style={{
                      width: '25%',
                      backgroundColor: 'white',
                      position:"relative",
                      padding: '40px 20px',
                      borderRadius:"20px",
                      boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                    }}
                  >
                    <div
                    style={{
                      display:"flex",
                      alignItems:"center",
                      justifyContent:'center',
                    }}>
                    <div className="payment-popuplogo stripe-logo-img">
                        <img src={Stripe} alt="Stripe" />
                    </div>
                  </div>
                           <button
                        onClick={handleClosePopup}
                        type="button"
                        style={{
                          width:"20px",
                          height:"20px",
                          top:"15px",
                          right:"15px",
                          backgroundColor: '#f44336',
                          color: 'white',
                          border: 'none',
                          borderRadius: '50%',
                          cursor: 'pointer',
                          position:"absolute",
                          display:"flex",
                          alignItems:"center",
                          justifyContent:'center',
                        }}
                      >
                        X
                      </button>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '2px',
                      }}
                    >
                      <CardElement
                        className="card"
                        options={{
                          hidePostalCode: true,
                          style: {
                            base: {
                              backgroundColor: 'white',
                            },
                          },
                        }}
                      />
                    </div>
                    <Grid className='max-width-full'>
                      <div className="form-group">
                        <input
                          name="full_name"
                          placeholder="Enter your full name here"
                          required
                        />
                      </div>
                    </Grid>
                    <Grid className='max-width-full'>
                      <div className="form-group">
                        <input
                          name="email"
                          placeholder="Enter Email here"
                          required
                        />
                      </div>
                    </Grid>
                    <div className='paypopup-btns'>
                      <button
                        onClick={isPaymentLoading ? null : confirmPayment}
                        type="submit"
                        style={{
                          marginTop: '10px',
                          padding: '10px 20px',
                          background: 'linear-gradient(94deg, #00acbf -35.57%, #9fd650 119.86%)',
                          color: 'white',
                          border: 'none',
                          borderRadius: '4px',
                          cursor: 'pointer',
                        }}
                      >
                        {isPaymentLoading ? "Loading..." : "Proceed to Pay"}
                      </button>
                    </div>

                  </div>
                </div>
              )}
              
              {/* MALUAL POPUP */}
              {manualPopupVisible && (
                <div
                  style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                  }}
                  onClick={handleManualClickOutside}
                >
                  <div
                    ref={popupRef}
                    style={{
                      width: '65%',
                      backgroundColor: 'white',
                      position:"relative",
                      padding: '20px 20px',
                      borderRadius:"20px",
                      boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                      
                    }}
                  >
                    <div className='manual-payment-df'>
                    <div className='manual-paylft'>
                    <div className='manual-img-bx'>
                          {<div className="payment-popuplogo">
                            <img src={onlineimgfi} alt="Stripe" />
                          </div> }
                    </div>
                        <div className="payment-settings-container">
                                              {/* <div>
                                              <h2 className="payment-title">Payment settings</h2>

                    <div className="plan-section">
                      <h3 className="plan-title">Basic plan</h3>
                      <p className="plan-description">With limited access to the template catalog and basic editing.</p>
                    </div>

                    <hr className="divider" />

                    <div className="transaction-info">
                      <div className="transaction-date">
                        <p>Transaction date:</p>
                        <p className='manual-bold'>30/04/2021</p>
                      </div>
                    
                      <div className="transaction-hour">
                        <p>Hour:</p>
                        <p className='manual-bold'>12:48 PM</p>
                      </div>
                    </div>

                    <hr className="dotted-divider" />

                    <div className="payment-summary">
                      <div className="summary-item">
                        <p>Invoice Price:</p>
                        <p className='manual-bold'>{invoiceData?.currency} {bookingData?.payment_details[0]?.invoice_price?.toFixed(2)}</p>
                      </div>
                      <div className="summary-item">
                        <p>Discount:</p>
                        <p className='manual-bold'>$0.00 USD</p>
                      </div>
                    </div>

                    <hr className="divider" />

                    <div className="total-section">
                      <p>Invoice Price:</p>
                      <p className="total-amount">{invoiceData?.currency} {bookingData?.payment_details[0]?.invoice_price?.toFixed(2)}</p>
                    </div>
                                              </div> */}
                          

                        </div>
                    </div>
                    <div className='manual-paycard-details'>
                    <h2 className="payment-title">Payment settings</h2>
                        <Grid container spacing={2}>
                          <Grid item xs={4} sm={6} md={6} className='max-width-full'>
                            <div className="form-group">
                            <label htmlFor="golfers-select">Bank name</label>
                               <select id="payment_type" onChange={(e) => setBankName(e.target.value)}>
                                {
                                  bankList?.map(bank=>
                                    <option value={bank}>{bank}</option>
                                  )
                                }
                              </select>
                            </div>
                          </Grid>
                          <Grid item xs={4} sm={6} md={6} className='max-width-full'>
                            <div className="form-group">
                            <label htmlFor="golfers-select">Payment mode</label>
                               <select id="payment_type" onChange={(e) => setPaymentMode(e.target.value)}>
                                {
                                  gateWayMode?.map(mode=>
                                    <option value={mode?.id+'--'+mode?.payment_mode}>{mode?.payment_mode}</option>
                                  )
                                }
                              </select>
                            </div>
                          </Grid>
                          <Grid item xs={4} sm={6} md={6} className='max-width-full'>
                            <div className="form-group">
                            <label htmlFor="golfers-select">Amount</label>
                              <input
                                name="bank_name"
                                value={credit_amount}
                                onChange={(e) => setAmount(e.target.value)}
                                placeholder="Enter amount"
                                required
                              />
                            </div>
                          </Grid>
                        
                         <Grid item xs={4} sm={6} md={6} className='max-width-full'>
                            <div className="form-group">
                            <label htmlFor="golfers-select">Tranaction Date</label>
                            <DatePicker
                            // id="date-picker"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="12 - 04 - 24"
                        />
                            </div>
                          </Grid>
                          <Grid item xs={4} sm={6} md={12} className='max-width-full'>
                            <div className="form-group">
                            <label htmlFor="golfers-select">Payment Remarks</label>
                              <textarea
                                name="dec"
                                placeholder="Enter Payment Remarks"
                                onChange={(e) => setDescription(e.target.value)}
                                required
                              ></textarea>
                            </div>
                          </Grid>
                          {/* <Grid className='max-width-full'>
                      <div className="form-group">
                       
                        <select id="payment_type" name="payment_type">
                        <option>Credit Card</option>
                        <option>Debit Card</option>
                        </select>
                      </div>
                    </Grid> */}

                          <Grid item xs={4} sm={6} md={12} className='max-width-full'>
                            <div className="form-group">
                            <label htmlFor="golfers-select">Upload File</label>
                              <input
                                type="file"
                                name="receipt"
                                onChange={handleFileSelect}
                                required
                              />
                            </div>
                          </Grid>
                        </Grid>
                      <div className='paypopup-btns'>
                        <button
                          onClick={isPaymentLoading ? null : confirmManualPayment}
                          type="submit"
                          style={{
                            marginTop: '10px',
                            padding: '10px 20px',
                            background: 'linear-gradient(94deg, #00acbf -35.57%, #9fd650 119.86%)',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                          }}
                        >
                          {isPaymentLoading ? "Loading..." : "Proceed to Pay"}
                        </button>
                      </div>
                    </div>
                  </div>
                           <button
                        onClick={handleCloseManual}
                        type="button"
                        style={{
                          width:"20px",
                          height:"20px",
                          top:"15px",
                          right:"15px",
                          backgroundColor: '#f44336',
                          color: 'white',
                          border: 'none',
                          borderRadius: '50%',
                          cursor: 'pointer',
                          position:"absolute",
                          display:"flex",
                          alignItems:"center",
                          justifyContent:'center',
                        }}
                      >
                        X
                      </button>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '2px',
                      }}
                    >
                     
                    </div>
                    

                  </div>
                </div>
              )}
               {/* PAY NOW  POPUP */}
               {payNowPopupVisible && (
                <div
                  style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                  }}
                  onClick={handlePayNowClickOutside}
                >
                  <div
                    ref={popupRef}
                    style={{
                      width: '65%',
                      backgroundColor: 'white',
                      position:"relative",
                      padding: '20px 20px',
                      borderRadius:"20px",
                      boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                      
                    }}
                  >
                    <div className='manual-payment-df'>
                    <div className='manual-paylft'>
                    <div className='manual-img-bx'>
                          {<div className="payment-popuplogo" style={{'height' : '450px'}}>
                            <img src={payNow} alt="Stripe" />
                          </div> }
                    </div>
                        <div className="payment-settings-container">
                          

                        </div>
                    </div>
                    <div className='manual-paycard-details'>
                    <h2 className="payment-title">Payment settings</h2>
                        <Grid container spacing={2}>
                          <Grid item xs={4} sm={6} md={6} className='max-width-full'>
                            <div className="form-group">
                            <label htmlFor="bank_name">Bank name</label>
                               <input
                                name="bank_name"
                                onChange={(e) => setPayNowBankName(e.target.value)}
                                placeholder="Enter bank name"
                                required
                              />
                            </div>
                          </Grid>
                          <Grid item xs={4} sm={6} md={6} className='max-width-full'>
                            <div className="form-group">
                            <label htmlFor="paymentMode">Payment mode</label>
                            <input
                                name="paymentMode"
                                placeholder="Enter payment mode"
                                required
                              />
                            </div>
                          </Grid>
                          <Grid item xs={4} sm={6} md={6} className='max-width-full'>
                            <div className="form-group">
                            <label htmlFor="golfers-select">Amount</label>
                              <input
                                name="bank_name"
                                value={payNowAmount}
                                onChange={(e) => setPayNowAmount(e.target.value)}
                                placeholder="Enter amount"
                                required
                              />
                            </div>
                          </Grid>
                        
                         <Grid item xs={4} sm={6} md={6} className='max-width-full'>
                            <div className="form-group">
                            <label htmlFor="golfers-select">Tranaction Date</label>
                            <DatePicker
                            // id="date-picker"
                            selected={payNowstartDate}
                            onChange={(date) => setPayNowStartDate(date)}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="12 - 04 - 24"
                        />
                            </div>
                          </Grid>
                          <Grid item xs={4} sm={6} md={12} className='max-width-full'>
                            <div className="form-group">
                            <label htmlFor="golfers-select">Payment Remarks</label>
                              <textarea
                                name="dec"
                                placeholder="Enter Payment Remarks"
                                onChange={(e) => setPayNowDescription(e.target.value)}
                                required
                              ></textarea>
                            </div>
                          </Grid>

                          <Grid item xs={4} sm={6} md={12} className='max-width-full'>
                            <div className="form-group">
                            <label htmlFor="golfers-select">Upload File</label>
                              <input
                                type="file"
                                name="receipt"
                                onChange={handlePayNowFileSelect}
                                required
                              />
                            </div>
                          </Grid>
                        </Grid>
                      <div className='paypopup-btns'>
                        <button
                          onClick={isPaymentLoading ? null : confirmPayNowPayment}
                          type="submit"
                          style={{
                            marginTop: '10px',
                            padding: '10px 20px',
                            background: 'linear-gradient(94deg, #00acbf -35.57%, #9fd650 119.86%)',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                          }}
                        >
                          {isPaymentLoading ? "Loading..." : "Proceed to Pay"}
                        </button>
                      </div>
                    </div>
                  </div>
                           <button
                        onClick={handleClosePayNow}
                        type="button"
                        style={{
                          width:"20px",
                          height:"20px",
                          top:"15px",
                          right:"15px",
                          backgroundColor: '#f44336',
                          color: 'white',
                          border: 'none',
                          borderRadius: '50%',
                          cursor: 'pointer',
                          position:"absolute",
                          display:"flex",
                          alignItems:"center",
                          justifyContent:'center',
                        }}
                      >
                        X
                      </button>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '2px',
                      }}
                    >
                     
                    </div>
                    

                  </div>
                </div>
              )}
              {/* /MANUAL POPUP END */}
              {selectedPayment ==="Stripe" ?
              <button onClick={handleButtonClick} type="submit" disabled ={notinvoiceGenerated} className="btn-payment"><span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
                <path d="M23 8V18C23 19.1 22.1 20 21 20H5C4.45 20 4 19.55 4 19C4 18.45 4.45 18 5 18H21V8C21 7.45 21.45 7 22 7C22.55 7 23 7.45 23 8ZM4 16C2.34 16 1 14.66 1 13V7C1 5.34 2.34 4 4 4H16C17.66 4 19 5.34 19 7V14C19 15.1 18.1 16 17 16H4ZM7 10C7 11.66 8.34 13 10 13C11.66 13 13 11.66 13 10C13 8.34 11.66 7 10 7C8.34 7 7 8.34 7 10Z" fill="white" />
              </svg></span>Proceed to Pay</button> : selectedPayment === 'Flywire' ? 
              <button onClick={pay} id="flywire-pay" type="submit" disabled ={notinvoiceGenerated} className="btn-payment"><span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
              <path d="M23 8V18C23 19.1 22.1 20 21 20H5C4.45 20 4 19.55 4 19C4 18.45 4.45 18 5 18H21V8C21 7.45 21.45 7 22 7C22.55 7 23 7.45 23 8ZM4 16C2.34 16 1 14.66 1 13V7C1 5.34 2.34 4 4 4H16C17.66 4 19 5.34 19 7V14C19 15.1 18.1 16 17 16H4ZM7 10C7 11.66 8.34 13 10 13C11.66 13 13 11.66 13 10C13 8.34 11.66 7 10 7C8.34 7 7 8.34 7 10Z" fill="white" />
            </svg></span>Proceed to Pay</button> : selectedPayment === 'RazorPay' ?
              <button onClick={handlePayment} type="submit" disabled ={notinvoiceGenerated} className="btn-payment"><span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
              <path d="M23 8V18C23 19.1 22.1 20 21 20H5C4.45 20 4 19.55 4 19C4 18.45 4.45 18 5 18H21V8C21 7.45 21.45 7 22 7C22.55 7 23 7.45 23 8ZM4 16C2.34 16 1 14.66 1 13V7C1 5.34 2.34 4 4 4H16C17.66 4 19 5.34 19 7V14C19 15.1 18.1 16 17 16H4ZM7 10C7 11.66 8.34 13 10 13C11.66 13 13 11.66 13 10C13 8.34 11.66 7 10 7C8.34 7 7 8.34 7 10Z" fill="white" />
            </svg></span>Proceed to Pay </button> : selectedPayment === 'PayNow' ?
              <button onClick={handlePayNow} type="submit" disabled ={notinvoiceGenerated} className="btn-payment"><span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
              <path d="M23 8V18C23 19.1 22.1 20 21 20H5C4.45 20 4 19.55 4 19C4 18.45 4.45 18 5 18H21V8C21 7.45 21.45 7 22 7C22.55 7 23 7.45 23 8ZM4 16C2.34 16 1 14.66 1 13V7C1 5.34 2.34 4 4 4H16C17.66 4 19 5.34 19 7V14C19 15.1 18.1 16 17 16H4ZM7 10C7 11.66 8.34 13 10 13C11.66 13 13 11.66 13 10C13 8.34 11.66 7 10 7C8.34 7 7 8.34 7 10Z" fill="white" />
            </svg></span>Proceed to Pay </button>  : selectedPayment === 'Paynow' ?
              <button onClick={handlePayNow} type="submit" disabled ={notinvoiceGenerated} className="btn-payment"><span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
              <path d="M23 8V18C23 19.1 22.1 20 21 20H5C4.45 20 4 19.55 4 19C4 18.45 4.45 18 5 18H21V8C21 7.45 21.45 7 22 7C22.55 7 23 7.45 23 8ZM4 16C2.34 16 1 14.66 1 13V7C1 5.34 2.34 4 4 4H16C17.66 4 19 5.34 19 7V14C19 15.1 18.1 16 17 16H4ZM7 10C7 11.66 8.34 13 10 13C11.66 13 13 11.66 13 10C13 8.34 11.66 7 10 7C8.34 7 7 8.34 7 10Z" fill="white" />
            </svg></span>Proceed to Pay </button> : 
              <button onClick={handleManualModalClick} type="submit" disabled ={notinvoiceGenerated} className="btn-payment"><span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
              <path d="M23 8V18C23 19.1 22.1 20 21 20H5C4.45 20 4 19.55 4 19C4 18.45 4.45 18 5 18H21V8C21 7.45 21.45 7 22 7C22.55 7 23 7.45 23 8ZM4 16C2.34 16 1 14.66 1 13V7C1 5.34 2.34 4 4 4H16C17.66 4 19 5.34 19 7V14C19 15.1 18.1 16 17 16H4ZM7 10C7 11.66 8.34 13 10 13C11.66 13 13 11.66 13 10C13 8.34 11.66 7 10 7C8.34 7 7 8.34 7 10Z" fill="white" />
            </svg></span>Proceed to Pay</button>
            }
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default PaymentPageDetails;
