import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BOOKING_DATA } from '../../Redux/constants/bookingConstants'
import {getGolfHolidayResults } from '../../Redux/actions/bookingActions'
import Layout from '../Layout';
import GolfExperianceView from './GolfExperianceView';

const GolfExperiencesList = () => {
    const [details, setDetails] = useState({'title':'Golftripz Events','meta_desc':'Golftripz Events','meta_keyword':'golftripz , events'})
    const dispatch = useDispatch();
    useEffect(() => {
        getExperiances()
       
     }, []);
     
     const getExperiances=async()=>{
       let bookinData = JSON.parse(localStorage.getItem("holidaysearch"));
       const ApiData = {
           'search':bookinData.holidaysearch,
           'date':new Date(bookinData.holidaystartdate).toISOString().split('T')[0]
       }
        dispatch({
            type: BOOKING_DATA,
            payload: ApiData
        })
        await  dispatch(getGolfHolidayResults(ApiData));
     }
    return (
        <Layout head={"Golf Experiences"} details={details}>
            <GolfExperianceView/>
        </Layout>
    )
}

export default GolfExperiencesList;
