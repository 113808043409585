import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import whatsappicon from '../../assets/images/_whatsapp_.png'
import Noimage from "../../assets/images/no-img.png";
import HomeTopBar from '../../components/HomeTopBar';
import Footer from '../../components/Footer';
import ModifySearch from './ModifySearch';
import ModifySearchForHoliday from './ModifySearchForHoliday';
import ModifySearchInstant from './ModifySearchInstant';
import _ from 'lodash';

const Layout = ({ children, type }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { previousRoute } = useSelector(state => state.auth);
    const [headerdata, setHeaderdata] = useState({});
    const [urlpath, setUrlPath] = useState("");



    useEffect(() => {
        // Set header data
        setUrlPath(location.pathname)
        setHeaderdata({
            title: 'Golftripz',
            meta_desc: 'Book Golf online tee times & golf holiday packages worldwide at Golftripz. Play Golf at any golf course of your choice across the world. Book Golf Breaks @ info@golftripz.com',
            meta_keyword: 'Play golf, world golf, golf tours, golf packages, golf tee times online, book golf, golf booking online, golf trips, golf travel, world golf tours, golf holiday packages, golf breaks, golf tee times, best golf courses, top 10 golf courses, golftripz'
        });
    }, [previousRoute, location.pathname, dispatch]);

    return (
        <>
            <Helmet>
                <title>{headerdata.title}</title>
                <meta name="description" content={headerdata.meta_desc} />
                <meta name="keywords" content={headerdata.meta_keyword} />
            </Helmet>
            <div className="full-width-home">
                <div className="page-header">
                    <div className="header-width-pad bg-nav-black">
                        <HomeTopBar />
                    </div>
                </div>
                
                <div className="full-con-width">
                {urlpath ==="/GolfHoliday" || urlpath ==="/SearchResult" || urlpath ==="/GolfEvents" || urlpath ==="/GolfExperiences" || urlpath.startsWith("/golf-holiday/") || urlpath ==="/InstantPackages" ?
                <>
                {type === 'inspackage' ? (
                    <ModifySearchInstant />
                ) : type === 'golfHoliday' || type === 'SearchResult' ? (
                    <ModifySearchForHoliday />
                ) : (
                    <ModifySearch />
                )}
                 </>
                    :""
                }
                    {children}
               

                    <div className='whatsapp-icon-fixed'>
                       <a href="https://wa.me/6587509320"> <img onError={(e) => {e.target.onerror = null;e.target.src = Noimage; }} src={whatsappicon} alt="icon" /></a>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Layout;
