import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../Layout';
import GolfHolidaysNew from './GolfHolidaysNew';
import { BOOKING_DATA } from '../../Redux/constants/bookingConstants'
import {getGolfHolidayResults } from '../../Redux/actions/bookingActions'
import {Helmet} from "react-helmet";
const Index = () => {
     const [details, setDetails] = useState({'title':'Golftripz holidays','meta_desc':'Golftripz holidays','meta_keyword':'holidays , golftripz'})
     const { selectedGolfHoliday, holidayBookingSuccess, bookingData, holidaybookingnotlogin } = useSelector(state => state.booking);
     
    return (
      
        <Layout details=''>
            <Helmet>
            <title>{selectedGolfHoliday?.golf_holidays?.meta_title}</title>
            <meta name="description" content={selectedGolfHoliday?.golf_holidays?.meta_description} />
            <meta name="keywords" content={selectedGolfHoliday?.golf_holidays?.meta_keyword} />
            </Helmet>
            <GolfHolidaysNew/>
        </Layout>
    )
}

export default Index;
